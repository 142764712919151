import React, { useEffect, useContext, useState, Fragment } from "react";
import {
  Box,
  Button,
  Card,
  CardActions,
  CardMedia,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  Paper,
  FormControlLabel,
  FormGroup,
  Grid,
  Input,
  Modal,
  Table,
  TableContainer,
  TextField,
  Typography,
  TableBody,
  TableCell,
  TableRow,
  CardContent,
} from "@mui/material";
import axios from "axios";
import cloneDeep from "lodash/cloneDeep";
import { consumeGet, URL } from "../../utils/constants";
import { AuthContext } from "../../context";
import Pagination from "rc-pagination";
import "./pagination.css";
import {
  Add,
  AssignmentLate,
  AttachFile,
  Delete,
  Edit,
  RemoveRedEye,
  SetMealRounded,
} from "@mui/icons-material";
import "react-responsive-carousel/lib/styles/carousel.min.css"; // requires a loader
import { Link } from "react-router-dom";
import { useTheme } from "@emotion/react";
import moment from "moment";
import Details from "./Details";
import NotificationsIcon from "@mui/icons-material/Notifications";
export default function Pending() {
  const theme = useTheme();
  const countPerPage = 10;

  const [selected, setSelected] = useState(null);
  const { orders, deleteOptions, editOrders } = useContext(AuthContext);
  const [errors, setErrors] = useState(null);
  const [modal, setModal] = useState({
    open: false,
    toConfirm: null,
    description: "",
  });
  const [currentPage, setCurrentPage] = useState(1);
  const [data, setData] = useState([]);
  const [collection, setCollection] = useState(
    cloneDeep(data.slice(0, countPerPage))
  );
  useEffect(() => {
    const nuevo = orders.filter((o) => o.pickup === "PENDING");
    setData([...nuevo]);
    setCollection(cloneDeep(nuevo.slice(0, countPerPage)));
  }, [orders]);

  const [deleteModal, handleDeleteModal] = useState(null);
  const handleConfirm = async (type, time) => {
    try {
      if (type === "PROCESSED") {
        console.log(type, time);
        const result = await axios.put(
          `${URL}orders/${selected._id}`,
          { pickTime: time, pickup: type },
          {
            headers: {
              Authorization: "Bearer " + localStorage.getItem("jwtToken"),
              "Access-Control-Allow-Origin": "*",
            },
          }
        );
        if (result.status === 200) {
          setModal({
            open: true,
            toConfirm: null,
            description: `Order No. ${selected.orderId} has passed to "In process"`,
          });
          setSelected(null);
        }
      }
      if (type === "READY") {
        setModal({
          description: `Order No. ${selected.orderId} will go to "To Pick up"`,
          open: true,
          toConfirm: selected._id,
        });
      }
    } catch (err) {
      console.log(err);
    }
  };
  const updatePage = (p) => {
    setCurrentPage(p);
    const to = countPerPage * p;
    const from = to - countPerPage;
    setCollection(cloneDeep(data.slice(from, to)));
  };

  const handleErase = async (id) => {
    const config = {
      headers: {
        Authorization: "Bearer " + localStorage.getItem("jwtToken"),
      },
    };

    const result = await axios.put(
      `${URL}orders/${modal.toConfirm}`,
      { pickup: "READY" },
      config
    );

    if (result.status === 200) {
      setSelected(null);
      setModal({ open: false, toConfirm: null, description: "" });
      editOrders(result.data);
    } else {
      console.log("errors");
    }
  };
  return (
    <Box>
      {selected && (
        <Details
          handleConfirm={handleConfirm}
          order={selected}
          onCancel={() => setSelected(null)}
          canEdit
        />
      )}
      <Dialog open={modal.open}>
        <Box style={{ padding: 12 }}>
          <DialogTitle
            style={{
              marginBottom: 20,
              textAlign: "center",
              fontSize: 20,
              fontWeight: 800,
            }}
          >
            ¡Excelente!
          </DialogTitle>
          <DialogContent>
            <Typography style={{ marginBottom: 12 }}>
              {modal.description}
            </Typography>
          </DialogContent>
          <DialogActions>
            {modal.toConfirm ? (
              <Fragment>
                <Button
                  color="inherit"
                  onClick={() =>
                    setModal({ open: false, toConfirm: null, description: "" })
                  }
                  variant="contained"
                  style={{ width: "50%" }}
                >
                  Cancelar
                </Button>
                <Button
                  style={{ width: "50%" }}
                  onClick={() => handleErase()}
                  variant="contained"
                >
                  Ok
                </Button>
              </Fragment>
            ) : (
              <Box display="flex" justifyContent="center" alignItems="center">
                <Button
                  style={{ width: "50%" }}
                  onClick={() =>
                    setModal({ open: false, toConfirm: null, description: "" })
                  }
                  variant="contained"
                >
                  Ok
                </Button>
              </Box>
            )}
          </DialogActions>
        </Box>
      </Dialog>
      <Box>
        <Typography style={{ fontSize: 21, fontWeight: 800 }}>
          Nuevas Ordenes
        </Typography>
        <TableContainer component={Paper}>
          <Table>
            <TableBody>
              {collection.length > 0 ? (
                collection.map((o) => (
                  <TableRow hover>
                    <TableCell>
                      <NotificationsIcon />
                    </TableCell>
                    <TableCell colSpan={2}>
                      {o.user.first_name + " " + o.user.last_name}
                    </TableCell>
                    <TableCell>{o.user.telefono}</TableCell>
                    <TableCell>{moment(o.createdAt).format("h:mma")}</TableCell>
                    <TableCell>Orden N° {o.orderId}</TableCell>
                    <TableCell>
                      <Button
                        onClick={() => setSelected(o)}
                        variant="contained"
                      >
                        Confirmar
                      </Button>
                    </TableCell>
                  </TableRow>
                ))
              ) : (
                <Box
                  width="100%"
                  height={400}
                  display="flex"
                  flexDirection="column"
                  justifyContent="center"
                  alignItems="center"
                >
                  <AssignmentLate style={{ fontSize: 92 }} />
                  <Typography>No se han encontrado ordenes</Typography>
                </Box>
              )}
            </TableBody>
          </Table>
        </TableContainer>
        <Box
          marginTop={3}
          display="flex"
          justifyContent="flex-end"
          alignItems="flex-end"
        >
          <Pagination
            pageSize={countPerPage}
            onChange={updatePage}
            current={currentPage}
            total={data.length}
          />
        </Box>
      </Box>
    </Box>
  );
}
