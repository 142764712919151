import React, { useEffect, useContext, useState } from "react";
import {
  Box,
  Button,
  Card,
  CardActions,
  CardMedia,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  Paper,
  FormControlLabel,
  FormGroup,
  Grid,
  Input,
  Modal,
  Table,
  TableContainer,
  TextField,
  Typography,
  TableBody,
  TableCell,
  TableRow,
  CardContent,
} from "@mui/material";
import axios from "axios";
import { consumeGet, URL } from "../utils/constants";
import { AuthContext } from "../context";
import Option from "./Option.png";
import {
  Add,
  AssignmentLate,
  AttachFile,
  ContentCopy,
  Delete,
  Edit,
  RemoveRedEye,
  SetMealRounded,
} from "@mui/icons-material";
import "react-responsive-carousel/lib/styles/carousel.min.css"; // requires a loader
import { Link } from "react-router-dom";
import { useTheme } from "@emotion/react";

export default function Options() {
  const theme = useTheme();
  const { options, setOptions, editOptions, deleteOptions, selectedMarket } =
    useContext(AuthContext);
  const [errors, setErrors] = useState(null);
  const [name, setName] = useState("");
  const [add, handleAdd] = useState({
    open: false,
    option: { name: "", options: [{ name: "", price: 0 }] },
    edit: false,
    onlySee: false,
  });
  const [loading, setLoading] = useState(false);
  const [filteredData, setFilteredData] = useState(options);
  const [deleteModal, handleDeleteModal] = useState(null);
  const handleConfirm = async (copy) => {
    try {
      setLoading(true);
      let data;
      if (copy) {
        data = copy;
      } else {
        data = add.option;
      }
      if (!add.edit) {
        data.market = selectedMarket._id;
      }
      const config = {
        headers: {
          Authorization: "Bearer " + localStorage.getItem("jwtToken"),
        },
      };

      let result = null;
      if (add.edit) {
        result = await axios.put(`${URL}options/${data._id}`, data, {
          headers: {
            Authorization: "Bearer " + localStorage.getItem("jwtToken"),
          },
        });
      } else {
        result = await axios.post(`${URL}options`, data, config);
      }
      if (result.status === 200) {
        if (add.edit) {
          setErrors(null);
          editOptions(result.data);
        } else {
          setOptions([...options, result.data]);
        }
        handleAdd({
          open: false,
          option: { name: "", options: [{ name: "", price: 0 }] },
          edit: false,
          onlySee: false,
        });
      } else {
        console.log("errors");
      }
      setLoading(false);
    } catch (err) {
      setLoading(false);
      setErrors(err.response.data.errors);
    }
  };
  const handleErase = async (id) => {
    const config = {
      headers: {
        Authorization: "Bearer " + localStorage.getItem("jwtToken"),
      },
    };

    const result = await axios.delete(`${URL}options/${deleteModal}`, config);
    handleDeleteModal(null);
    if (result.status === 200) {
      deleteOptions(result.data);
    } else {
      console.log("errors");
    }
  };
  useEffect(() => {
    if (name.trim() !== "") {
      let result = options.filter((o) => {
        const regex = new RegExp("^" + name, "i");
        return regex.test(o.name);
      })
      setFilteredData([...result]);
    } else {
      setFilteredData([...options]);
    }
  }, [name, options]);
  return (
    <Box>
      <Dialog fullWidth maxWidth="md" open={add.open}>
        {add.onlySee && (
          <Button
            variant="contained"
            onClick={() =>
              handleAdd({
                open: false,
                option: { name: "", options: [{ name: "", price: 0 }] },
                edit: false,
                onlySee: false,
              })
            }
            style={{
              position: "absolute",
              top: 5,
              right: 5,
              height: 40,
              width: 40,
              borderRadius: 40,
            }}
          >
            <Typography fontSize={20}>X</Typography>
          </Button>
        )}
        <DialogTitle>Añadir opción</DialogTitle>
        <DialogContent>
          <FormGroup aria-label="position" style={{ marginBottom: 20 }} row>
            <FormControlLabel
              control={
                <TextField
                  disabled={add.onlySee}
                  style={{
                    marginLeft: 24,
                    boxShadow: "rgb(116 116 116) 1px 2px 4px",
                    borderRadius: 4,
                  }}
                  value={add.option.name}
                  onChange={(e) =>
                    handleAdd({
                      ...add,
                      option: { ...add.option, name: e.target.value },
                    })
                  }
                />
              }
              label="Nombre de la lista de opciones"
              labelPlacement="start"
            />
          </FormGroup>
          <Grid container>
            <Grid container>
              <Grid xs={2}></Grid>
              <Grid xs={6}>
                <Typography textAlign="center">Nombre</Typography>
              </Grid>
              <Grid xs={4}>
                <Typography textAlign="center">Precio</Typography>
              </Grid>
            </Grid>
          </Grid>
          {add.option.options.map((o, i) => (
            <Grid style={{ marginBottom: 20 }} container>
              <Grid xs={2}>
                <Button
                  style={{
                    height: "100%",
                    width: "100%",
                    display: "flex",
                    justifyContent: "center",
                    alignItems: "center",
                    fontWeight: "bold",
                    color: "black",
                  }}
                  onClick={() => {
                    let options = add.option.options;
                    if (options.length > 1) {
                      options.splice(i, 1);
                    }
                    handleAdd({ ...add, option: { ...add.option, options } });
                  }}
                  disabled={add.onlySee}
                >
                  <Typography fontSize={20} textAlign="center">
                    X
                  </Typography>
                </Button>
              </Grid>
              <Grid xs={6}>
                <Box width="90%">
                  <TextField
                    disabled={add.onlySee}
                    style={{
                      marginLeft: 24,
                      boxShadow: "rgb(116 116 116) 1px 2px 4px",
                      borderRadius: 4,
                    }}
                    value={o.name}
                    fullWidth
                    onChange={(e) => {
                      const options = add.option.options;
                      options[i] = { ...options[i], name: e.target.value };
                      handleAdd({
                        ...add,
                        option: { ...add.option, options: options },
                      });
                    }}
                  />
                </Box>
              </Grid>
              <Grid xs={4}>
                <Box
                  width="90%"
                  display="flex"
                  justifyContent="center"
                  alignItems="center"
                >
                  <Typography marginRight={-2}>$</Typography>
                  <TextField
                    disabled={add.onlySee}
                    value={o.price}
                    type="number"
                    onChange={(e) => {
                      const options = add.option.options;
                      options[i] = { ...options[i], price: e.target.value };
                      handleAdd({
                        ...add,
                        option: { ...add.option, options: options },
                      });
                    }}
                    fullWidth
                    style={{
                      marginLeft: 24,
                      boxShadow: "rgb(116 116 116) 1px 2px 4px",
                      borderRadius: 4,
                    }}
                  />
                </Box>
              </Grid>
            </Grid>
          ))}
          {!add.onlySee && (
            <Box
              display="flex"
              style={{ marginTop: 20 }}
              justifyContent="flex-end"
              alignItems="flex-end"
            >
              <Button
                variant="outlined"
                style={{
                  backgroundColor: "white",
                  boxShadow: "rgb(116 116 116) 1px 2px 4px",
                }}
                disabled={loading}
                onClick={() => {
                  let actual = add.option.options;
                  actual.push({ name: "", price: 0 });
                  handleAdd({
                    ...add,
                    option: { ...add.option, options: actual },
                  });
                }}
              >
                <Typography>Añadir</Typography>
                <Add />
              </Button>
            </Box>
          )}
          {errors && (
            <Card style={{ width: "80%", marginLeft: "10%" }}>
              <CardContent>
                {Object.values(errors).map((e) => (
                  <Typography color="#df4759" textAlign="center">
                    *{e}
                  </Typography>
                ))}
              </CardContent>
            </Card>
          )}
        </DialogContent>
        {!add.onlySee && (
          <DialogActions>
            <Button
              color="inherit"
              onClick={() =>
                handleAdd({
                  open: false,
                  edit: false,
                  onlySee: false,
                  option: { options: [{ name: "", price: 0 }], name: "" },
                })
              }
              variant="contained"
            >
              Cancelar
            </Button>
            <Button onClick={() => handleConfirm()} variant="contained">
              Ok
            </Button>
          </DialogActions>
        )}
      </Dialog>
      <Dialog open={deleteModal}>
        <Box style={{ padding: 12 }}>
          <DialogTitle
            style={{
              marginBottom: 20,
              textAlign: "center",
              fontSize: 20,
              fontWeight: 800,
            }}
          >
            Eliminar lista de opción
          </DialogTitle>
          <DialogContent>
            <Typography style={{ marginBottom: 12 }}>
              ¿Estas seguro? Perderas toda la información de la lista de opción
            </Typography>
          </DialogContent>
          <DialogActions>
            <Button
              color="inherit"
              onClick={() => handleDeleteModal(null)}
              variant="contained"
              style={{ width: "50%" }}
            >
              Cancelar
            </Button>
            <Button
              style={{ width: "50%" }}
              onClick={() => handleErase()}
              variant="contained"
            >
              Ok
            </Button>
          </DialogActions>
        </Box>
      </Dialog>
      <Box
        display="flex"
        justifyContent="space-between"
        alignItems="center"
        width="100%"
        style={{ marginBottom: 12 }}
      >
        <Box display="flex">
          <Link to="/categories" style={{ textDecoration: "none" }}>
            <Typography
              fontSize={25}
              color={theme.palette.primary.main}
              fontWeight={800}
              variant="h2"
              style={{ marginRight: 8 }}
            >
              {"<"}
            </Typography>
          </Link>
          <Link to="/categories" style={{ textDecoration: "none" }}>
            <Typography
              fontSize={25}
              color="#5a5959"
              fontWeight={800}
              variant="h2"
            >
              Categorias
            </Typography>
          </Link>
          <Typography
            fontSize={25}
            color={theme.palette.primary.main}
            fontWeight={800}
            variant="h2"
            style={{ paddingLeft: 8, paddingRight: 8 }}
          >
            {">"}
          </Typography>
          <Typography
            fontSize={25}
            color="#5a5959"
            fontWeight={800}
            variant="h2"
          >
            Opciones
          </Typography>
        </Box>
        <Box display="flex" justifyContent="center" alignItems="center">
          <Box marginRight={3}>
            <Typography>Nombre</Typography>
            <TextField value={name} onChange={(e) => setName(e.target.value)} />
          </Box>
          <Button
            variant="outlined"
            style={{
              height: 40,
              backgroundColor: "white",
              boxShadow: "rgb(116 116 116) 1px 2px 4px",
            }}
            onClick={() =>
              handleAdd({
                open: true,
                edit: false,
                onlySee: false,
                option: { name: "", options: [{ name: "", price: 0 }] },
              })
            }
          >
            <Typography>Añadir</Typography>
            <Add />
          </Button>
        </Box>
      </Box>
      <Box>
        <Typography>Nombre</Typography>
        <TableContainer component={Paper}>
          <Table>
            <TableBody>

              {filteredData.length > 0 ? (
                filteredData.sort((a, b) => a.name > b.name ? 1 : -1).map((o) => (
                  <TableRow>
                    <TableCell width="80%" colSpan={2}>
                      {o.name}
                    </TableCell>
                    <TableCell>
                      <Box
                        style={{
                          display: "flex",
                          justifyContent: "flex-end",
                          alignItems: "flex-end",
                        }}
                      >
                        <Button style={{ margin: 0, padding: 0, minWidth: 32 }}>
                          <Edit
                            onClick={() =>
                              handleAdd({
                                onlySee: false,
                                edit: true,
                                open: true,
                                option: o,
                              })
                            }
                            style={{ color: "black" }}
                          />
                        </Button>
                        <Button style={{ margin: 0, padding: 0, minWidth: 32 }}>
                          <Delete
                            onClick={() => handleDeleteModal(o._id)}
                            style={{ color: "black" }}
                          />
                        </Button>
                        <Button style={{ margin: 0, padding: 0, minWidth: 32 }}>
                          <ContentCopy
                            onClick={() =>
                              handleConfirm(o)
                            }
                            style={{ color: "black" }}
                          />
                        </Button>
                        <Button
                          onClick={() =>
                            handleAdd({
                              edit: false,
                              open: true,
                              onlySee: true,
                              option: { ...o },
                            })
                          }
                          style={{ margin: 0, padding: 0, minWidth: 32 }}
                        >
                          <RemoveRedEye style={{ color: "black" }} />
                        </Button>
                      </Box>
                    </TableCell>
                  </TableRow>
                ))
              ) : (
                <Box
                  width="100%"
                  height={400}
                  display="flex"
                  flexDirection="column"
                  justifyContent="center"
                  alignItems="center"
                >
                  <AssignmentLate style={{ fontSize: 92 }} />
                  <Typography>No se han encontrado listas de opciones</Typography>
                </Box>
              )}
            </TableBody>
          </Table>
        </TableContainer>
      </Box>
    </Box>
  );
}
