import React, { useEffect, useContext, useState, Fragment } from "react";
import {
  Box,
  Button,
  Card,
  CardActions,
  CardMedia,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  Paper,
  FormControlLabel,
  FormGroup,
  Grid,
  Input,
  Modal,
  Table,
  TableContainer,
  TextField,
  TableHead,
  Typography,
  TableBody,
  TableCell,
  TableRow,
  CardContent,
} from "@mui/material";
import axios from "axios";
import { consumeGet, URL } from "../../utils/constants";
import { AuthContext } from "../../context";
import {
  Add,
  AssignmentLate,
  AttachFile,
  Delete,
  Edit,
  RemoveRedEye,
  SetMealRounded,
} from "@mui/icons-material";
import { AdapterMoment } from "@mui/x-date-pickers/AdapterMoment";
import { LocalizationProvider } from "@mui/x-date-pickers/LocalizationProvider";
import "react-responsive-carousel/lib/styles/carousel.min.css"; // requires a loade
import { useTheme } from "@emotion/react";
import moment from "moment";
import NotificationsIcon from "@mui/icons-material/Notifications";
import Details from "../orders/Details";
import { MobileDatePicker } from "@mui/x-date-pickers/MobileDatePicker";

export default function Reserves({ handleExit }) {
  const theme = useTheme();
  const [switcher, setSwitcher] = useState(1);
  const [orders, setOrders] = useState([]);
  const [selected, setSelected] = useState(null);
  const { selectedMarket } = useContext(AuthContext);
  const [filters, setFilters] = useState({
    orderId: "",
    customer: "",
    pickup: null,
  });
  const exportData = async () => {
    const result = await axios.get(URL + "exportOrders", {
      params: { ...filters, market: selectedMarket._id },
      responseType: "blob",
      headers: {
        Authorization: "Bearer " + localStorage.getItem("jwtToken"),
      },
    });
    const url = window.URL.createObjectURL(new Blob([result.data]));
    const link = document.createElement("a");
    link.href = url;
    link.setAttribute("download", "orders.xlsx"); //or any other extension
    document.body.appendChild(link);
    link.click();
  };
  useEffect(() => {
    const tryFunct = async () => {
      try {
        const result = await consumeGet("orders", {
          status: "READY",
          market: selectedMarket._id,
        });

        if (result.status === 200) {
          setOrders(result.data);
        } else {
          console.log(result);
        }
      } catch (err) {
        console.log(err);
      }
    };
    tryFunct();
  }, [selectedMarket]);
  const [modal, setModal] = useState({
    open: false,
    toConfirm: null,
    description: "",
  });

  const data = orders.filter((o) => {
    if (filters.orderId.trim() !== "") {
      const regex = new RegExp("^" + filters.orderId, "i");
      if (!regex.test(o.orderId)) {
        return false;
      }
    }
    if (filters.customer.trim() !== "") {
      const regex = new RegExp("^" + filters.customer, "i");
      if (!regex.test(o.user.first_name)) {
        return false;
      }
    }
    if (filters.pickup) {
      const previus = moment(o.pickUpIn);
      const actual = filters.pickup;
      if (
        previus.date() !== actual.date() ||
        previus.month() !== actual.month()
      ) {
        return false;
      }
    }
    return true;
  });
  return (
    <LocalizationProvider dateAdapter={AdapterMoment}>
      <Box position="relative">
        <Box>
          {selected && (
            <Details
              order={selected}
              onlySee
              onCancel={() => setSelected(null)}
            />
          )}
          <Box position="absolute" top={-15} left={1}>
            <Button onClick={() => handleExit()}>
              <Typography fontSize={38}>{"<"}</Typography>
            </Button>
          </Box>
          <Box display={"flex"} marginBottom={5} justifyContent={"center"}>
            <Button
              onClick={() => setSwitcher(1)}
              style={{ marginRight: 40 }}
              color={switcher === 2 ? "inherit" : "primary"}
              variant="contained"
            >
              Historial de reservas
            </Button>
            <Button
              onClick={() => setSwitcher(2)}
              color={switcher === 1 ? "inherit" : "primary"}
              variant="contained"
            >
              Reservas pendientes
            </Button>
          </Box>

          <Box
            marginBottom={5}
            display="flex"
            alignItems="center"
            justifyContent="space-around"
          >
            <Box>
              <Typography>Dia</Typography>
              <Box display="flex">
                <Box style={{ marginRight: 8 }}>
                  <MobileDatePicker
                    inputFormat="DD/MM/YY"
                    value={filters.pickup}
                    onChange={(newValue) =>
                      setFilters({ ...filters, pickup: newValue })
                    }
                    renderInput={(params) => <TextField {...params} />}
                  />
                </Box>
                <Box display="flex" justifyContent="center" alignItems="center">
                  <Button
                    onClick={(newValue) =>
                      setFilters({ ...filters, pickup: null })
                    }
                    variant="contained"
                  >
                    X
                  </Button>
                </Box>
              </Box>
            </Box>
            <Box>
              <Typography>Producto</Typography>
              <TextField
                value={filters.customer}
                onChange={(e) =>
                  setFilters({ ...filters, customer: e.target.value })
                }
              />
            </Box>
            <Box>
              <Typography>N° Reserva</Typography>
              <TextField
                value={filters.orderId}
                onChange={(e) =>
                  setFilters({ ...filters, orderId: e.target.value })
                }
              />
            </Box>
          </Box>
          {data.length > 0 ? (
            <TableContainer component={Paper}>
              <Table>
                <TableHead>
                  <TableCell>
                    <Typography fontWeight={800}>Producto</Typography>
                  </TableCell>
                  <TableCell>
                    <Typography fontWeight={800}>Dia</Typography>
                  </TableCell>
                  <TableCell>
                    <Typography fontWeight={800}>Cliente</Typography>
                  </TableCell>
                  <TableCell>
                    <Typography fontWeight={800}>N° Reserva</Typography>
                  </TableCell>
                  {switcher === 1 ? (
                    <TableCell>
                      <Typography fontWeight={800}>
                        Estado de reserva
                      </Typography>
                    </TableCell>
                  ) : (
                    <>
                      <TableCell>
                        <Typography fontWeight={800}>Rechazar</Typography>
                      </TableCell>
                      <TableCell>
                        <Typography fontWeight={800}>Aprobar</Typography>
                      </TableCell>
                    </>
                  )}
                </TableHead>
                <TableBody>
                  <TableRow hover>
                    <TableCell>Línea de bolos para 4 personas</TableCell>
                    <TableCell>14/09/2024</TableCell>
                    <TableCell>Christian Duarte</TableCell>
                    <TableCell>N° 990812140924</TableCell>
                    {switcher === 1 ? (
                      <TableCell>
                        <Typography>Aprobada</Typography>
                      </TableCell>
                    ) : (
                      <>
                        <TableCell>
                          <Button color="primary" variant="contained">
                            Rechazar
                          </Button>
                        </TableCell>
                        <TableCell>
                          <Button color="inherit" variant="contained">
                            Aprobar
                          </Button>
                        </TableCell>
                      </>
                    )}
                  </TableRow>
                </TableBody>
              </Table>
            </TableContainer>
          ) : (
            <Box
              width="100%"
              height={400}
              display="flex"
              flexDirection="column"
              justifyContent="center"
              alignItems="center"
            >
              <AssignmentLate style={{ fontSize: 92 }} />
              <Typography>No se han encontrado ordenes</Typography>
            </Box>
          )}
        </Box>
      </Box>
    </LocalizationProvider>
  );
}
