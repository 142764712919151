import React, { useEffect, useContext, useState } from "react";
import {
  Box,
  Button,
  Card,
  CardActions,
  CardMedia,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  Paper,
  FormControlLabel,
  FormGroup,
  Grid,
  Input,
  Modal,
  Table,
  TableContainer,
  TextField,
  Typography,
  TableBody,
  TableCell,
  TableRow,
  CardContent,
  TableHead,
} from "@mui/material";
import axios from "axios";
import { consumeGet, URL } from "../utils/constants";
import { AuthContext } from "../context";
import Option from "./Option.png";
import {
  Add,
  AssignmentLate,
  AttachFile,
  ContentCopy,
  Delete,
  Edit,
  RemoveRedEye,
  SetMealRounded,
} from "@mui/icons-material";
import "react-responsive-carousel/lib/styles/carousel.min.css"; // requires a loader
import { Link } from "react-router-dom";
import { useTheme } from "@emotion/react";
import moment from "moment";
import { MobileDatePicker } from "@mui/x-date-pickers/MobileDatePicker";
import { AdapterMoment } from "@mui/x-date-pickers/AdapterMoment";
import { LocalizationProvider } from "@mui/x-date-pickers/LocalizationProvider";
export default function Codes({ handleExit }) {
  const theme = useTheme();
  const { codes, setCodes, deleteCode, selectedMarket } =
    useContext(AuthContext);
  const [errors, setErrors] = useState(null);
  const [add, handleAdd] = useState({
    codeName: "",
    code: "",
    upTo: 0,
    discount: 0,
    validAt: null,
  });
  const [loading, setLoading] = useState(false);
  const [deleteModal, handleDeleteModal] = useState(null);
  const handleConfirm = async () => {
    try {
      setLoading(true);
      const config = {
        headers: {
          Authorization: "Bearer " + localStorage.getItem("jwtToken"),
        },
      };
      const result = await axios.post(
        `${URL}codes`,
        { ...add, market: selectedMarket._id },
        config
      );
      if (result.status === 200) {
        setCodes([...codes, result.data]);
        handleAdd({
          codeName: "",
          code: "",
          upTo: 0,
          discount: 0,
          validAt: null,
        });
        setErrors(null);
      } else {
        console.log("errors");
      }
      setLoading(false);
    } catch (err) {
      setLoading(false);
      setErrors(err.response.data.errors);
    }
  };
  const handleErase = async () => {
    const config = {
      headers: {
        Authorization: "Bearer " + localStorage.getItem("jwtToken"),
      },
    };

    const result = await axios.delete(`${URL}codes/${deleteModal._id}`, config);
    handleDeleteModal(null);
    if (result.status === 200) {
      deleteCode(deleteModal);
    } else {
      console.log("errors");
    }
  };
  return (
    <LocalizationProvider dateAdapter={AdapterMoment}>
      <Box>
        <Box display={"flex"} alignItems={"center"}>
          <Button onClick={() => handleExit()}>
            <Typography fontSize={38}>{"<"}</Typography>
          </Button>
          <Typography
            fontSize={25}
            color={theme.palette.primary.main}
            fontWeight={800}
            variant="h2"
            style={{ marginBottom: 8 }}
          >
            Nuevo Codigo
          </Typography>
        </Box>

        <Card style={{ marginBottom: 8 }} fullWidth maxWidth="md">
          <CardContent>
            <Grid container>
              <Grid xs={3}>
                <FormGroup
                  aria-label="position"
                  style={{ marginBottom: 20, width: "100%" }}
                  row
                >
                  <FormControlLabel
                    control={
                      <TextField
                        style={{
                          marginLeft: 24,
                          boxShadow: "rgb(116 116 116) 1px 2px 4px",
                          borderRadius: 4,
                        }}
                        fullWidth
                        value={add.codeName}
                        onChange={(e) =>
                          handleAdd({ ...add, codeName: e.target.value })
                        }
                      />
                    }
                    style={{ width: "100%" }}
                    label="Identificador"
                    labelPlacement="top"
                  />
                </FormGroup>
              </Grid>
              <Grid xs={3}>
                <FormGroup
                  aria-label="position"
                  style={{ marginBottom: 20 }}
                  row
                >
                  <FormControlLabel
                    control={
                      <TextField
                        style={{
                          marginLeft: 24,
                          boxShadow: "rgb(116 116 116) 1px 2px 4px",
                          borderRadius: 4,
                        }}
                        fullWidth
                        value={add.code}
                        onChange={(e) =>
                          handleAdd({ ...add, code: e.target.value })
                        }
                      />
                    }
                    style={{ width: "100%" }}
                    label="Codigo"
                    labelPlacement="top"
                  />
                </FormGroup>
              </Grid>
              <Grid xs={2}>
                <FormGroup
                  aria-label="position"
                  style={{ marginBottom: 20 }}
                  row
                >
                  <FormControlLabel
                    control={
                      <TextField
                        style={{
                          marginLeft: 24,
                          boxShadow: "rgb(116 116 116) 1px 2px 4px",
                          borderRadius: 4,
                        }}
                        type="number"
                        value={add.discount}
                        onChange={(e) => {
                          if (e.target.value >= 0) {
                            handleAdd({ ...add, discount: e.target.value });
                          }
                        }}
                      />
                    }
                    label="Descuento"
                    labelPlacement="top"
                  />
                </FormGroup>
              </Grid>
              <Grid xs={2}>
                <FormGroup
                  aria-label="position"
                  style={{ marginBottom: 20 }}
                  row
                >
                  <FormControlLabel
                    control={
                      <TextField
                        style={{
                          marginLeft: 24,
                          boxShadow: "rgb(116 116 116) 1px 2px 4px",
                          borderRadius: 4,
                        }}
                        type="number"
                        value={add.upTo}
                        onChange={(e) => {
                          if (e.target.value >= 0) {
                            handleAdd({ ...add, upTo: e.target.value });
                          }
                        }}
                      />
                    }
                    label="Por encima de"
                    labelPlacement="top"
                  />
                </FormGroup>
              </Grid>
              <Grid xs={2}>
                <Typography>Expiración</Typography>
                <Box display="flex">
                  <Box style={{ marginRight: 8 }}>
                    <MobileDatePicker
                      inputFormat="DD/MM/YY"
                      minDate={moment()}
                      value={add.validAt}
                      onChange={(newValue) =>
                        handleAdd({ ...add, validAt: newValue })
                      }
                      renderInput={(params) => <TextField {...params} />}
                    />
                  </Box>
                </Box>
              </Grid>
            </Grid>
            {errors && (
              <Card
                style={{ width: "80%", marginLeft: "10%", marginBottom: 8 }}
              >
                <CardContent>
                  {Object.values(errors).map((e) => (
                    <Typography color="#df4759" textAlign="center">
                      *{e}
                    </Typography>
                  ))}
                </CardContent>
              </Card>
            )}
            <Box
              style={{
                display: "flex",
                justifyContent: "flex-end",
                alignItems: "end",
              }}
            >
              <Button onClick={() => handleConfirm()} variant="contained">
                Añadir nuevo codigo
              </Button>
            </Box>
          </CardContent>
        </Card>
        <Dialog open={deleteModal}>
          <Box style={{ padding: 12 }}>
            <DialogTitle
              style={{
                marginBottom: 20,
                textAlign: "center",
                fontSize: 20,
                fontWeight: 800,
              }}
            >
              Eliminar Codigo
            </DialogTitle>
            <DialogContent>
              <Typography style={{ marginBottom: 12 }}>
                ¿Estas seguro? Perderas toda información sobre el codigo de
                descuento
              </Typography>
            </DialogContent>
            <DialogActions>
              <Button
                color="inherit"
                onClick={() => handleDeleteModal(null)}
                variant="contained"
                style={{ width: "50%" }}
              >
                Cancelar
              </Button>
              <Button
                style={{ width: "50%" }}
                onClick={() => handleErase()}
                variant="contained"
              >
                Ok
              </Button>
            </DialogActions>
          </Box>
        </Dialog>
        <Box>
          <TableContainer>
            <Table>
              <TableHead>
                <TableRow>
                  <TableCell style={{ fontWeight: "800", textAlign: "center" }}>
                    Identificador
                  </TableCell>
                  <TableCell style={{ fontWeight: "800", textAlign: "center" }}>
                    Codigo
                  </TableCell>
                  <TableCell style={{ fontWeight: "800", textAlign: "center" }}>
                    %
                  </TableCell>
                  <TableCell style={{ fontWeight: "800", textAlign: "center" }}>
                    Encima de
                  </TableCell>
                  <TableCell style={{ fontWeight: "800", textAlign: "center" }}>
                    Fecha de creación
                  </TableCell>
                  <TableCell style={{ fontWeight: "800", textAlign: "center" }}>
                    Fecha de expiración
                  </TableCell>
                  <TableCell
                    style={{ fontWeight: "800", textAlign: "center" }}
                  ></TableCell>
                </TableRow>
              </TableHead>
              <TableBody>
                {codes.length > 0 ? (
                  codes
                    .sort((a, b) => (a.codeName > b.codeName ? 1 : -1))
                    .map((o) => (
                      <TableRow>
                        <TableCell style={{ textAlign: "center" }}>
                          {o.codeName}
                        </TableCell>
                        <TableCell style={{ textAlign: "center" }}>
                          {o.code}
                        </TableCell>
                        <TableCell style={{ textAlign: "center" }}>
                          {o.discount}%
                        </TableCell>
                        <TableCell style={{ textAlign: "center" }}>
                          ${o.upTo}
                        </TableCell>
                        <TableCell style={{ textAlign: "center" }}>
                          {moment(o.createdAt).format("DD/MM/YY")}
                        </TableCell>
                        <TableCell style={{ textAlign: "center" }}>
                          {moment(o.validAt).format("DD/MM/YY")}
                        </TableCell>
                        <TableCell>
                          <Button
                            onClick={() => handleDeleteModal(o)}
                            style={{ margin: 0, padding: 0, minWidth: 32 }}
                          >
                            <Delete style={{ color: "black" }} />
                          </Button>
                        </TableCell>
                      </TableRow>
                    ))
                ) : (
                  <Box
                    width="100%"
                    height={400}
                    display="flex"
                    flexDirection="column"
                    justifyContent="center"
                    alignItems="center"
                  >
                    <AssignmentLate style={{ fontSize: 92 }} />
                    <Typography>
                      No se encontraron codigos de descuento creados
                    </Typography>
                  </Box>
                )}
              </TableBody>
            </Table>
          </TableContainer>
        </Box>
      </Box>
    </LocalizationProvider>
  );
}
