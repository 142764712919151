import React, { useEffect, useState, useContext, Fragment } from "react";
import {
  Box,
  Grid,
  Card,
  CardContent,
  Table,
  TableBody,
  TableHead,
  TableCell,
  Typography,
  TextField,
  TableRow,
  Button,
  DialogActions,
  DialogContent,
  DialogTitle,
  Dialog,
  Select,
  MenuItem,
} from "@mui/material";
import { AttachFile, Edit, Group, Person, Store } from "@mui/icons-material";
import { consumeGet, URL } from "../utils/constants";
import { AdapterMoment } from "@mui/x-date-pickers/AdapterMoment";
import { LocalizationProvider } from "@mui/x-date-pickers/LocalizationProvider";
import { TimePicker } from "@mui/x-date-pickers/TimePicker";
import moment from "moment";
import DeleteIcon from "@mui/icons-material/Delete";
import { AuthContext } from "../context";
import axios from "axios";

export default function Configuration() {
  const [admins, setAdmins] = useState([]);
  const [dates, setDates] = useState(null);
  const [loading, setLoading] = useState(false);

  const {
    user,
    logout,
    markets,
    setMarkets,
    setSelected,
    editMarkets,
    deleteMarket,
    selectedMarket: seleccionado,
  } = useContext(AuthContext);
  const [selectedMarket, setSelectedMarket] = useState({
    open: false,
    edit: false,
    market: { name: "", direction: "", phone: "", stripeId: "", photo: null, tags: seleccionado.tags, iva: seleccionado.iva },
  });
  const [selectedTag, setSelectedTag] = useState({
    open: false,
    edit: false,
    tag: ""
  });
  const [selectedAdmin, setSelectedAdmin] = useState({
    open: false,
    edit: false,
    admin: {
      username: "",
      email: "",
      password: "",
      confirmPassword: "",
      market: "",
    },
  });
  const [errors, setErrors] = useState(null);
  const [deleteModalMarket, setDeleteModalMarket] = useState(null);
  const [deleteModalAdmin, setDeleteModalAdmin] = useState(null);
  const handleEraseAdmin = async () => {
    const config = {
      headers: {
        Authorization: "Bearer " + localStorage.getItem("jwtToken"),
      },
    };

    const result = await axios.delete(
      `${URL}admin/${deleteModalAdmin}`,
      config
    );
    setDeleteModalAdmin(null);
    if (result.status === 200) {
      const filteredAdmins = admins.filter((a) => a._id !== result.data._id);
      setAdmins([...filteredAdmins]);
    } else {
      console.log("errors");
    }
  };
  const handleEraseMarket = async () => {
    const config = {
      headers: {
        Authorization: "Bearer " + localStorage.getItem("jwtToken"),
      },
    };

    const result = await axios.delete(
      `${URL}markets/${deleteModalMarket}`,
      config
    );
    setDeleteModalMarket(null);
    if (result.status === 200) {
      deleteMarket(result.data);
    } else {
      console.log("errors");
    }
  };
  const uploadMarket = async () => {
    try {
      setLoading(true);
      let formData;
      if (!selectedMarket.edit) {
        formData = {
          ...selectedMarket.market,
          day1: {
            open: "07:00",
            close: "20:00",
          },
          day2: {
            open: "07:00",
            close: "20:00",
          },
          day3: {
            open: "07:00",
            close: "20:00",
          },
          day4: {
            open: "07:00",
            close: "20:00",
          },
          day5: {
            open: "07:00",
            close: "20:00",
          },
          day6: {
            open: "07:00",
            close: "20:00",
          },
          day7: {
            open: "07:00",
            close: "20:00",
          },
        };
      } else {
        formData = selectedMarket.market;
      }
      const config = {
        headers: {
          Authorization: "Bearer " + localStorage.getItem("jwtToken"),
          "Access-Control-Allow-Origin": "*",
        },
      };

      let result = null;
      if (selectedMarket.edit) {
        result = await axios.put(
          `${URL}markets/${selectedMarket.market._id}`,
          formData,
          {
            headers: {
              Authorization: "Bearer " + localStorage.getItem("jwtToken"),
              "Access-Control-Allow-Origin": "*",
            },
          }
        );
      } else {
        result = await axios.post(`${URL}markets`, formData, config);
      }
      if (result.status === 200) {
        setErrors(null);
        if (selectedMarket.edit) {
          editMarkets(result.data);
        } else {
          setMarkets([...markets, result.data]);
        }
        setSelectedMarket({ open: false, market: {}, edit: false });
      } else {
        setErrors(result.data);
      }
      setLoading(false);
    } catch (err) {
      setLoading(false);

      setErrors(err.response.data.errors);
    }
  };
  const uploadAdmin = async () => {
    try {
      setLoading(true);
      let formData;
      if (selectedAdmin.edit) {
        formData = selectedAdmin.admin;
      } else {
        formData = { ...selectedAdmin.admin, isSuperAdmin: false };
      }
      formData.userId = user._id;
      const config = {
        headers: {
          Authorization: "Bearer " + localStorage.getItem("jwtToken"),
          "Access-Control-Allow-Origin": "*",
        },
      };

      let result = null;
      if (selectedAdmin.edit) {
        result = await axios.put(
          `${URL}admin/${selectedAdmin.admin._id}`,
          formData,
          {
            headers: {
              Authorization: "Bearer " + localStorage.getItem("jwtToken"),
              "Access-Control-Allow-Origin": "*",
            },
          }
        );
      } else {
        result = await axios.post(`${URL}admin`, formData, config);
      }
      if (result.status === 200) {
        setErrors(null);
        if (selectedAdmin.edit) {
          const arrayAdminds = admins.map((a) => {
            if (result.data._id === a._id) {
              return result.data;
            } else {
              return a;
            }
          });
          setAdmins([...arrayAdminds]);
        } else {
          setAdmins([...admins, result.data]);
        }
        setSelectedAdmin({ open: false, admin: {}, edit: false });
      } else {
        setErrors(result.data);
      }
      setLoading(false);
    } catch (err) {
      setLoading(false);

      setErrors(err.response.data.errors);
    }
  };
  useEffect(() => {
    const tryFunct = async () => {
      try {
        const result = await consumeGet("admin", {});

        if (result.status === 200) {
          setAdmins(result.data);
        } else {
          console.log(result);
        }
      } catch (err) {
        console.log(err);
      }
    };
    tryFunct();
  }, []);
  const saveTime = async () => {
    try {
      const formData = new FormData();
      dates.forEach((p, i) => {
        const resulted = p;
        resulted.open = `${p.open.hours()}:${p.open.minutes()}`;
        resulted.close = `${p.close.hours()}:${p.close.minutes()}`;
        formData.append("day" + (i + 1) + ".open", resulted.open);
        formData.append("day" + (i + 1) + ".close", resulted.close);
      });
      selectedMarket.market.tags.forEach((t, i) => {
        formData.append("tags[" + i + "]", t)
      })
      if (selectedMarket.market.photo) {
        formData.append("image", selectedMarket.market.photo);
      }

      const result = await axios.put(
        `${URL}markets/${seleccionado._id}`,
        formData,
        {
          headers: {
            Authorization: "Bearer " + localStorage.getItem("jwtToken"),
            "Access-Control-Allow-Origin": "*",
          },
        }
      );
      if (result.status === 200) {
        editMarkets(result.data);
      } else {
        setErrors(result.data);
      }
    } catch (err) {
      console.log(err);
      setErrors(err);
    }
  };
  useEffect(() => {
    if (seleccionado) {
      const days = seleccionado;
      setDates([
        {
          open: moment()
            .hours(parseInt(days.day1.open.split(":")[0]))
            .minutes(parseInt(days.day1.open.split(":")[1])),
          close: moment()
            .hours(parseInt(days.day1.close.split(":")[0]))
            .minutes(parseInt(days.day1.close.split(":")[1])),
        },
        {
          open: moment()
            .hours(parseInt(days.day2.open.split(":")[0]))
            .minutes(parseInt(days.day2.open.split(":")[1])),
          close: moment()
            .hours(parseInt(days.day2.close.split(":")[0]))
            .minutes(parseInt(days.day2.close.split(":")[1])),
        },
        {
          open: moment()
            .hours(parseInt(days.day3.open.split(":")[0]))
            .minutes(parseInt(days.day3.open.split(":")[1])),
          close: moment()
            .hours(parseInt(days.day3.close.split(":")[0]))
            .minutes(parseInt(days.day3.close.split(":")[1])),
        },
        {
          open: moment()
            .hours(parseInt(days.day4.open.split(":")[0]))
            .minutes(parseInt(days.day4.open.split(":")[1])),
          close: moment()
            .hours(parseInt(days.day4.close.split(":")[0]))
            .minutes(parseInt(days.day4.close.split(":")[1])),
        },
        {
          open: moment()
            .hours(parseInt(days.day5.open.split(":")[0]))
            .minutes(parseInt(days.day5.open.split(":")[1])),
          close: moment()
            .hours(parseInt(days.day5.close.split(":")[0]))
            .minutes(parseInt(days.day5.close.split(":")[1])),
        },
        {
          open: moment()
            .hours(parseInt(days.day6.open.split(":")[0]))
            .minutes(parseInt(days.day6.open.split(":")[1])),
          close: moment()
            .hours(parseInt(days.day6.close.split(":")[0]))
            .minutes(parseInt(days.day6.close.split(":")[1])),
        },
        {
          open: moment()
            .hours(parseInt(days.day7.open.split(":")[0]))
            .minutes(parseInt(days.day7.open.split(":")[1])),
          close: moment()
            .hours(parseInt(days.day7.close.split(":")[0]))
            .minutes(parseInt(days.day7.close.split(":")[1])),
        },
      ]);
    }
  }, [seleccionado]);
  const ToUseLater = (
    <Grid xs={4.8}>
      <Card>
        <CardContent>
          <Box
            marginTop={3}
            display="flex"
            justifyContent="center"
            alignItems="center"
          >
            <Button
              onClick={() =>
                setSelectedMarket({
                  open: true,
                  market: { name: "", direction: "" },
                  edit: false,
                })
              }
              variant="contained"
            >
              Añadir Tienda
            </Button>
          </Box>
          <Box display="flex" justifyContent="center" alignItems="center">
            <Box marginRight={2}>
              <Store style={{ fontSize: 82 }} />
            </Box>
            <Table>
              <TableBody>
                {markets.map((a) => (
                  <TableRow>
                    <TableCell>{a.name}</TableCell>
                    <TableCell>{a.direction}</TableCell>
                    <TableCell>
                      <Button
                        onClick={() =>
                          setSelectedMarket({
                            open: true,
                            market: a,
                            edit: true,
                          })
                        }
                        color="inherit"
                      >
                        <Edit />
                      </Button>
                    </TableCell>
                    <TableCell>
                      <Button
                        onClick={() => setDeleteModalMarket(a._id)}
                        color="inherit"
                      >
                        <DeleteIcon />
                      </Button>
                    </TableCell>
                  </TableRow>
                ))}
              </TableBody>
            </Table>
          </Box>
        </CardContent>
      </Card>
    </Grid>
  );
  if (!markets.length > 0 || !seleccionado || !dates)
    return <Typography>Cargando...</Typography>;
  return (
    <LocalizationProvider dateAdapter={AdapterMoment}>
      <Dialog open={deleteModalMarket}>
        <Box style={{ padding: 12 }}>
          <DialogTitle
            style={{
              marginBottom: 20,
              textAlign: "center",
              fontSize: 20,
              fontWeight: 800,
            }}
          >
            Eliminar Tienda
          </DialogTitle>
          <DialogContent>
            <Typography style={{ marginBottom: 12 }}>
              ¿Estas seguro? Perderas toda información sobre esta tienda
            </Typography>
          </DialogContent>
          <DialogActions>
            <Button
              color="inherit"
              onClick={() => setDeleteModalMarket(null)}
              variant="contained"
              style={{ width: "50%" }}
            >
              Cancelar
            </Button>
            <Button
              style={{ width: "50%" }}
              onClick={() => handleEraseMarket()}
              variant="contained"
            >
              Ok
            </Button>
          </DialogActions>
        </Box>
      </Dialog>
      <Dialog open={deleteModalAdmin}>
        <Box style={{ padding: 12 }}>
          <DialogTitle
            style={{
              marginBottom: 20,
              textAlign: "center",
              fontSize: 20,
              fontWeight: 800,
            }}
          >
            Eliminar administrador
          </DialogTitle>
          <DialogContent>
            <Typography style={{ marginBottom: 12 }}>
              ¿Estas seguro? Perderas toda información sobre este administrador
            </Typography>
          </DialogContent>
          <DialogActions>
            <Button
              color="inherit"
              onClick={() => setDeleteModalAdmin(null)}
              variant="contained"
              style={{ width: "50%" }}
            >
              Cancelar
            </Button>
            <Button
              style={{ width: "50%" }}
              onClick={() => handleEraseAdmin()}
              variant="contained"
            >
              Ok
            </Button>
          </DialogActions>
        </Box>
      </Dialog>
      <Dialog open={selectedAdmin.open}>
        <Button
          onClick={() =>
            setSelectedAdmin({
              open: false,
              edit: false,
              admin: {
                username: "",
                email: "",
                password: "",
                confirmPassword: "",
                market: "",
              },
            })
          }
          variant="contained"
          style={{
            position: "absolute",
            top: 5,
            right: 5,
            height: 40,
            width: 40,
            borderRadius: 40,
          }}
        >
          <Typography fontSize={20}>X</Typography>
        </Button>
        <Box style={{ padding: 12 }}>
          <DialogTitle
            style={{
              marginBottom: 20,
              textAlign: "center",
              fontSize: 20,
              fontWeight: 800,
            }}
          >
            Añadir administrador
          </DialogTitle>
          <DialogContent>
            <Grid
              style={{
                justifyContent: "center",
                alignItems: "center",
                marginBottom: 15,
              }}
              container
            >
              <Grid xs={4}>
                <Typography style={{ marginRight: 8 }}>Nombre</Typography>
              </Grid>
              <Grid xs={8}>
                <TextField
                  placeholder="Name"
                  value={selectedAdmin.admin.username}
                  onChange={(e) =>
                    setSelectedAdmin({
                      ...selectedAdmin,
                      admin: {
                        ...selectedAdmin.admin,
                        username: e.target.value,
                      },
                    })
                  }
                />
              </Grid>
            </Grid>
            <Grid
              style={{
                justifyContent: "center",
                alignItems: "center  ",
                marginBottom: 18,
              }}
              container
            >
              <Grid xs={4}>
                <Typography style={{ marginRight: 8 }}>Correo</Typography>
              </Grid>
              <Grid xs={8}>
                <TextField
                  placeholder="Mail"
                  value={selectedAdmin.admin.email}
                  onChange={(e) =>
                    setSelectedAdmin({
                      ...selectedAdmin,
                      admin: {
                        ...selectedAdmin.admin,
                        email: e.target.value,
                      },
                    })
                  }
                />
              </Grid>
            </Grid>
            <Grid
              style={{
                justifyContent: "center",
                alignItems: "center  ",
                marginBottom: 18,
              }}
              container
            >
              <Grid xs={4}>
                <Typography style={{ marginRight: 8 }}>Tienda</Typography>
              </Grid>
              <Grid xs={8}>
                <Select
                  placeholder="Market"
                  value={selectedAdmin.admin.market}
                  fullWidth
                  style={{ maxWidth: "90.666667%" }}
                  onChange={(e) =>
                    setSelectedAdmin({
                      ...selectedAdmin,
                      admin: {
                        ...selectedAdmin.admin,
                        market: e.target.value,
                      },
                    })
                  }
                >
                  {markets.map((m, i) => (
                    <MenuItem key={i} value={m._id}>
                      {m.name}
                    </MenuItem>
                  ))}
                </Select>
              </Grid>
            </Grid>
            <Grid
              style={{
                justifyContent: "center",
                alignItems: "center  ",
                marginBottom: 18,
              }}
              container
            >
              <Grid xs={4}>
                <Typography style={{ marginRight: 8 }}>Constraseña</Typography>
              </Grid>
              <Grid xs={8}>
                <TextField
                  placeholder="Password"
                  value={selectedAdmin.admin.password}
                  onChange={(e) =>
                    setSelectedAdmin({
                      ...selectedAdmin,
                      admin: {
                        ...selectedAdmin.admin,
                        password: e.target.value,
                      },
                    })
                  }
                />
              </Grid>
            </Grid>
            <Grid
              style={{
                justifyContent: "center",
                alignItems: "center  ",
                marginBottom: 18,
              }}
              container
            >
              <Grid xs={4}>
                <Typography style={{ marginRight: 8 }}>
                  Confirmar contraseña
                </Typography>
              </Grid>
              <Grid xs={8}>
                <TextField
                  placeholder="Confirm Password"
                  value={selectedAdmin.admin.confirmPassword}
                  onChange={(e) =>
                    setSelectedAdmin({
                      ...selectedAdmin,
                      admin: {
                        ...selectedAdmin.admin,
                        confirmPassword: e.target.value,
                      },
                    })
                  }
                />
              </Grid>
            </Grid>
            {errors && (
              <Card style={{ width: "80%", marginLeft: "10%" }}>
                <CardContent>
                  {Object.values(errors).map((e) => (
                    <Typography color="#df4759" textAlign="center">
                      *{e}
                    </Typography>
                  ))}
                </CardContent>
              </Card>
            )}
          </DialogContent>
          <DialogActions>
            <Fragment>
              <Button
                style={{ width: "50%" }}
                onClick={() => uploadAdmin()}
                variant="contained"
                disabled={loading}
              >
                Guardar
              </Button>
            </Fragment>
          </DialogActions>
        </Box>
      </Dialog>
      <Dialog open={selectedTag.open}>
        <Button
          onClick={() =>
            setSelectedTag({
              open: false,
              tag: "",
              edit: false,
            })
          }
          variant="contained"
          style={{
            position: "absolute",
            top: 5,
            right: 5,
            height: 40,
            width: 40,
            borderRadius: 40,
          }}
        >
          <Typography fontSize={20}>X</Typography>
        </Button>
        <Box style={{ padding: 12 }}>
          <DialogTitle
            style={{
              marginBottom: 20,
              textAlign: "center",
              fontSize: 20,
              fontWeight: 800,
            }}
          >
            Añadir un Tag
          </DialogTitle>
          <DialogContent>
            <Grid
              style={{
                justifyContent: "center",
                alignItems: "center",
                marginBottom: 15,
              }}
              container
            >
              <Grid xs={4}>
                <Typography style={{ marginRight: 8 }}>Tag a añadir</Typography>
              </Grid>
              <Grid xs={8}>
                <TextField
                  placeholder="Tag"
                  value={selectedTag.tag}
                  onChange={(e) =>
                    setSelectedTag({
                      ...selectedTag,
                      tag: e.target.value
                    })
                  }
                />
              </Grid>
            </Grid>
          </DialogContent>
          <DialogActions>
            <Fragment>
              <Button
                style={{ width: "50%" }}
                onClick={() => {
                  setSelectedMarket({ ...selectedMarket, market: { ...selectedMarket.market, tags: [...selectedMarket.market.tags, selectedTag.tag] } })
                  setSelectedTag({ open: false, edit: false, tag: "" })
                  saveTime();
                }}
                variant="contained"
                disabled={loading}
              >
                Guardar
              </Button>
            </Fragment>
          </DialogActions>
        </Box>
      </Dialog>
      <Dialog open={selectedMarket.open}>
        <Button
          onClick={() =>
            setSelectedMarket({
              open: false,
              edit: false,
              market: { name: "", direction: "", phone: "" },
            })
          }
          variant="contained"
          style={{
            position: "absolute",
            top: 5,
            right: 5,
            height: 40,
            width: 40,
            borderRadius: 40,
          }}
        >
          <Typography fontSize={20}>X</Typography>
        </Button>
        <Box style={{ padding: 12 }}>
          <DialogTitle
            style={{
              marginBottom: 20,
              textAlign: "center",
              fontSize: 20,
              fontWeight: 800,
            }}
          >
            Add Store
          </DialogTitle>
          <DialogContent>
            <Grid
              style={{
                justifyContent: "center",
                alignItems: "center",
                marginBottom: 15,
              }}
              container
            >
              <Grid xs={4}>
                <Typography style={{ marginRight: 8 }}>Name</Typography>
              </Grid>
              <Grid xs={8}>
                <TextField
                  placeholder="name"
                  value={selectedMarket.market.name}
                  onChange={(e) =>
                    setSelectedMarket({
                      ...selectedMarket,
                      market: {
                        ...selectedMarket.market,
                        name: e.target.value,
                      },
                    })
                  }
                />
              </Grid>
            </Grid>
            <Grid
              style={{ justifyContent: "center", alignItems: "center  " }}
              container
            >
              <Grid xs={4}>
                <Typography style={{ marginRight: 8 }}>Adress</Typography>
              </Grid>
              <Grid xs={8}>
                <TextField
                  placeholder="Adress"
                  value={selectedMarket.market.direction}
                  onChange={(e) =>
                    setSelectedMarket({
                      ...selectedMarket,
                      market: {
                        ...selectedMarket.market,
                        direction: e.target.value,
                      },
                    })
                  }
                />
              </Grid>
            </Grid>
            <Grid
              style={{
                justifyContent: "center",
                alignItems: "center  ",
                marginTop: 15,
              }}
              container
            >
              <Grid xs={4}>
                <Typography style={{ marginRight: 8 }}>Phone</Typography>
              </Grid>
              <Grid xs={8}>
                <TextField
                  placeholder="Phone"
                  value={selectedMarket.market.phone}
                  onChange={(e) =>
                    setSelectedMarket({
                      ...selectedMarket,
                      market: {
                        ...selectedMarket.market,
                        phone: e.target.value,
                      },
                    })
                  }
                />
              </Grid>
              <Grid
                style={{
                  justifyContent: "center",
                  alignItems: "center  ",
                  marginTop: 15,
                }}
                container
              >
                <Grid xs={4}>
                  <Typography style={{ marginRight: 8 }}>Stripe ID</Typography>
                </Grid>
                <Grid xs={8}>
                  <TextField
                    placeholder="Stripe ID"
                    value={selectedMarket.market.stripeId}
                    onChange={(e) =>
                      setSelectedMarket({
                        ...selectedMarket,
                        market: {
                          ...selectedMarket.market,
                          stripeId: e.target.value,
                        },
                      })
                    }
                  />
                </Grid>
              </Grid>
            </Grid>
            {errors && (
              <Card style={{ width: "80%", marginLeft: "10%" }}>
                <CardContent>
                  {Object.values(errors).map((e) => (
                    <Typography color="#df4759" textAlign="center">
                      *{e}
                    </Typography>
                  ))}
                </CardContent>
              </Card>
            )}
          </DialogContent>
          <DialogActions>
            <Fragment>
              <Button
                style={{ width: "50%" }}
                onClick={() => uploadMarket()}
                variant="contained"
                disabled={loading}
              >
                Save
              </Button>
            </Fragment>
          </DialogActions>
        </Box>
      </Dialog>
      <Box>
        <Box
          width="100%"
          marginBottom={2}
          display="flex"
          justifyContent="center"
          alignItems="center"
        >
          <Card>
            <CardContent>
              <Box
                display="flex"
                justifyContent="center"
                alignItems="center"
                marginRight={2}
              >
                <Person color="primary" style={{ fontSize: 82 }} />
              </Box>
              <Box>
                <Typography>{user.username}</Typography>
                <Typography>{user.email}</Typography>
                <Box
                  display="flex"
                  flexDirection="column"
                  justifyContent="center"
                  alignItems="center"
                  marginTop={2}
                >
                  <Button onClick={logout} variant="contained">
                    Cerrar sesión
                  </Button>
                </Box>
              </Box>
            </CardContent>
          </Card>
        </Box>
        <Box
          display="flex"
          marginBottom={2}
          justifyContent="center"
          alignItems="center"
          position="relative"
          height={60}
        >
          <Typography
            style={{ marginRight: 12, fontSize: 24, fontWeight: 800 }}
          >
            Horario de atención
          </Typography>
          <Box position="absolute" right={2} top={0}>
            <Select
              placeholder="Market"
              value={seleccionado}
              onChange={(e) => setSelected(e.target.value)}
            >
              {markets.map((m, i) => (
                <MenuItem key={i} value={m}>
                  {m.name}
                </MenuItem>
              ))}
            </Select>
          </Box>
        </Box>

        <Grid container>
          <Grid xs={12}>
            <Card>
              <CardContent>
                <Table>
                  <TableHead>
                    <TableCell></TableCell>
                    <TableCell>Apertura</TableCell>
                    <TableCell>Cierre</TableCell>
                  </TableHead>
                  <TableBody>
                    <TableRow>
                      <TableCell>
                        <Typography fontWeight={800}>Lunes</Typography>
                      </TableCell>
                      <TableCell>
                        <TimePicker
                          label="Time"
                          value={dates[0].open}
                          onChange={(newValue) => {
                            const parsedMarket = dates;
                            parsedMarket[0].open = newValue;
                            setDates([...parsedMarket]);
                          }}
                          renderInput={(params) => <TextField {...params} />}
                        />
                      </TableCell>
                      <TableCell>
                        <TimePicker
                          label="Time"
                          value={dates[0].close}
                          onChange={(newValue) => {
                            const parsedMarket = dates;
                            parsedMarket[0].close = newValue;
                            setDates([...parsedMarket]);
                          }}
                          renderInput={(params) => <TextField {...params} />}
                        />
                      </TableCell>
                    </TableRow>
                    <TableRow>
                      <TableCell>
                        <Typography fontWeight={800}>Martes</Typography>
                      </TableCell>
                      <TableCell>
                        <TimePicker
                          label="Time"
                          value={dates[1].open}
                          onChange={(newValue) => {
                            const parsedMarket = dates;
                            parsedMarket[1].open = newValue;
                            setDates([...parsedMarket]);
                          }}
                          renderInput={(params) => <TextField {...params} />}
                        />
                      </TableCell>
                      <TableCell>
                        <TimePicker
                          label="Time"
                          value={dates[1].close}
                          onChange={(newValue) => {
                            const parsedMarket = dates;
                            parsedMarket[1].close = newValue;
                            setDates([...parsedMarket]);
                          }}
                          renderInput={(params) => <TextField {...params} />}
                        />
                      </TableCell>
                    </TableRow>
                    <TableRow>
                      <TableCell>
                        <Typography fontWeight={800}>MIercoles</Typography>
                      </TableCell>
                      <TableCell>
                        <TimePicker
                          label="Time"
                          value={dates[2].open}
                          onChange={(newValue) => {
                            const parsedMarket = dates;
                            parsedMarket[2].open = newValue;
                            setDates([...parsedMarket]);
                          }}
                          renderInput={(params) => <TextField {...params} />}
                        />
                      </TableCell>
                      <TableCell>
                        <TimePicker
                          label="Time"
                          value={dates[2].close}
                          onChange={(newValue) => {
                            const parsedMarket = dates;
                            parsedMarket[2].close = newValue;
                            setDates([...parsedMarket]);
                          }}
                          renderInput={(params) => <TextField {...params} />}
                        />
                      </TableCell>
                    </TableRow>
                    <TableRow>
                      <TableCell>
                        <Typography fontWeight={800}>Jueves</Typography>
                      </TableCell>
                      <TableCell>
                        <TimePicker
                          label="Time"
                          value={dates[3].open}
                          onChange={(newValue) => {
                            const parsedMarket = dates;
                            parsedMarket[3].open = newValue;
                            setDates([...parsedMarket]);
                          }}
                          renderInput={(params) => <TextField {...params} />}
                        />
                      </TableCell>
                      <TableCell>
                        <TimePicker
                          label="Time"
                          value={dates[3].close}
                          onChange={(newValue) => {
                            const parsedMarket = dates;
                            parsedMarket[3].close = newValue;
                            setDates([...parsedMarket]);
                          }}
                          renderInput={(params) => <TextField {...params} />}
                        />
                      </TableCell>
                    </TableRow>
                    <TableRow>
                      <TableCell>
                        <Typography fontWeight={800}>Viernes</Typography>
                      </TableCell>
                      <TableCell>
                        <TimePicker
                          label="Time"
                          value={dates[4].open}
                          onChange={(newValue) => {
                            const parsedMarket = dates;
                            parsedMarket[4].open = newValue;
                            setDates([...parsedMarket]);
                          }}
                          renderInput={(params) => <TextField {...params} />}
                        />
                      </TableCell>
                      <TableCell>
                        <TimePicker
                          label="Time"
                          value={dates[4].close}
                          onChange={(newValue) => {
                            const parsedMarket = dates;
                            parsedMarket[4].close = newValue;
                            setDates([...parsedMarket]);
                          }}
                          renderInput={(params) => <TextField {...params} />}
                        />
                      </TableCell>
                    </TableRow>
                    <TableRow>
                      <TableCell>
                        <Typography fontWeight={800}>Sabado</Typography>
                      </TableCell>
                      <TableCell>
                        <TimePicker
                          label="Time"
                          value={dates[5].open}
                          onChange={(newValue) => {
                            const parsedMarket = dates;
                            parsedMarket[5].open = newValue;
                            setDates([...parsedMarket]);
                          }}
                          renderInput={(params) => <TextField {...params} />}
                        />
                      </TableCell>
                      <TableCell>
                        <TimePicker
                          label="Time"
                          value={dates[5].close}
                          onChange={(newValue) => {
                            const parsedMarket = dates;
                            parsedMarket[5].close = newValue;
                            setDates([...parsedMarket]);
                          }}
                          renderInput={(params) => <TextField {...params} />}
                        />
                      </TableCell>
                    </TableRow>
                    <TableRow>
                      <TableCell>
                        <Typography fontWeight={800}>Domingo</Typography>
                      </TableCell>
                      <TableCell>
                        <TimePicker
                          label="Time"
                          value={dates[6].open}
                          onChange={(newValue) => {
                            const parsedMarket = dates;
                            parsedMarket[6].open = newValue;
                            setDates([...parsedMarket]);
                          }}
                          renderInput={(params) => <TextField {...params} />}
                        />
                      </TableCell>
                      <TableCell>
                        <TimePicker
                          label="Time"
                          value={dates[6].close}
                          onChange={(newValue) => {
                            const parsedMarket = dates;
                            parsedMarket[6].close = newValue;
                            setDates([...parsedMarket]);
                          }}
                          renderInput={(params) => <TextField {...params} />}
                        />
                      </TableCell>
                    </TableRow>
                  </TableBody>
                </Table>
                <Box style={{ display: "flex", justifyContent: "center", alignItems: "center", flexDirection: "column", marginBottom: 20 }} width={"100%"}>
                  <Box >Cambiar IVA:</Box>
                  <TextField
                    placeholder="Mail"
                    fullWidth={false}
                    type="number"
                    value={selectedMarket.market.iva}
                    onChange={(e) =>
                      setSelectedMarket({
                        ...selectedMarket,
                        market: { ...selectedMarket.market, iva: e.target.value },
                      })
                    }
                  />
                </Box>
                {seleccionado.photo ? (
                  <Box width={"100%"}>

                    <Box>

                      Foto Actual:
                    </Box>
                    <Box width={"100%"} justifyContent="center" alignItems="center" style={{ display: "flex" }}>
                      <img src={URL + seleccionado.photo} width={500} height={500} alt="foto del restaurante" />
                    </Box>
                    <Box>
                      <Box>Cambiar foto:</Box>
                      <Button
                        style={{ marginLeft: 24 }}
                        variant="outlined"
                        component="label"
                      >
                        <AttachFile />
                        <input
                          onChange={(e) =>
                            setSelectedMarket({
                              ...selectedMarket,
                              market: { ...selectedMarket.market, photo: e.target.files[0] },
                            })
                          }
                          type="file"
                          hidden
                        />
                      </Button>
                    </Box>
                  </Box>
                ) : (
                  <Box width={"100%"} flex flexDirection={"column"}>

                    <Box flex justifyContent={"center"} alignItems={"center"} color={"black"} fontWeight={"800"} width={"100%"}>

                      Aun no has añadido una foto para tu restaurante
                    </Box>
                    <Box>
                      <Box>Añadir foto:</Box>
                      <Button
                        style={{ marginLeft: 24 }}
                        variant="outlined"
                        component="label"
                      >
                        <AttachFile />
                        <input
                          onChange={(e) =>
                            setSelectedMarket({
                              ...selectedMarket,
                              market: { ...selectedMarket.market, photo: e.target.files[0] },
                            })
                          }
                          type="file"
                          hidden
                        />
                      </Button>
                    </Box>
                  </Box>
                )}
                <Box
                  display="flex"
                  marginTop={2}
                  justifyContent="center"
                  alignItems="center"
                >
                  <Button onClick={saveTime} variant="contained">
                    Guardar
                  </Button>
                </Box>
              </CardContent>
            </Card>
          </Grid>
        </Grid>
        <Grid style={{ marginTop: 8 }} container>
          <Grid marginRight={2} xs={12}>
            <Card>
              <CardContent>
                <Box
                  marginTop={3}
                  display="flex"
                  justifyContent="center"
                  alignItems="center"
                >
                  <Button
                    onClick={() =>
                      setSelectedTag({
                        open: true,
                        tag: "",
                        edit: true,
                      })
                    }
                    variant="contained"
                  >
                    Añadir Tag
                  </Button>
                </Box>
                <Box display="flex" justifyContent="center" alignItems="center">
                  <Box marginRight={2}>
                    <Group style={{ fontSize: 82 }} />
                  </Box>
                  <Table>
                    <TableBody>
                      {selectedMarket.market.tags.map((a) => (
                        <TableRow>
                          <TableCell>{a}</TableCell>
                          <TableCell>
                            <Button
                              onClick={() => setSelectedMarket({ ...selectedMarket, market: { ...selectedMarket.market, tags: selectedMarket.market.tags.filter((t) => t !== a) } })}
                              color="inherit"
                            >
                              <DeleteIcon />
                            </Button>
                          </TableCell>
                        </TableRow>
                      ))}
                    </TableBody>
                  </Table>
                </Box>
              </CardContent>
            </Card>
          </Grid>
        </Grid>
        <Grid style={{ marginTop: 8 }} container>
          <Grid marginRight={2} xs={12}>
            <Card>
              <CardContent>
                <Box
                  marginTop={3}
                  display="flex"
                  justifyContent="center"
                  alignItems="center"
                >
                  <Button
                    onClick={() =>
                      setSelectedAdmin({
                        open: true,
                        admin: {
                          name: "",
                          email: "",
                          password: "",
                          confirmPassword: "",
                          market: "",
                        },
                        edit: false,
                      })
                    }
                    variant="contained"
                  >
                    Añadir Usuario
                  </Button>
                </Box>
                <Box display="flex" justifyContent="center" alignItems="center">
                  <Box marginRight={2}>
                    <Group style={{ fontSize: 82 }} />
                  </Box>
                  <Table>
                    <TableBody>
                      {admins.map((a) => (
                        <TableRow>
                          <TableCell>{a.username}</TableCell>
                          <TableCell>{a.email}</TableCell>
                          <TableCell>
                            <Button
                              onClick={() =>
                                setSelectedAdmin({
                                  open: true,
                                  edit: true,
                                  admin: { ...a, password: "" },
                                })
                              }
                              color="inherit"
                            >
                              <Edit />
                            </Button>
                          </TableCell>
                          <TableCell>
                            <Button
                              onClick={() => setDeleteModalAdmin(a._id)}
                              color="inherit"
                            >
                              <DeleteIcon />
                            </Button>
                          </TableCell>
                        </TableRow>
                      ))}
                    </TableBody>
                  </Table>
                </Box>
              </CardContent>
            </Card>
          </Grid>
        </Grid>
      </Box>
    </LocalizationProvider >
  );
}
