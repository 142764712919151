import React, { useReducer, createContext } from "react";
import jwtDecode from "jwt-decode";
import { isArray } from "lodash";

const initialState = {
  user: null,
  categories: [],
  markets: [],
  selectedMarket: null,
  options: [],
  codes: [],
  features: [],
  products: [],
  orders: [],
  counter: 0,
  loaderOrders: false,
  loaderCodes: false,
  loaderCategory: false,
  loaderOption: false,
};

if (localStorage.getItem("jwtToken")) {
  const decodedToken = jwtDecode(localStorage.getItem("jwtToken"));

  if (decodedToken.exp * 1000 < Date.now()) {
    localStorage.removeItem("jwtToken");
  } else {
    initialState.user = decodedToken;
  }
}

const AuthContext = createContext({
  user: null,
  login: (userData) => { },
  logout: () => { },
  categories: [],
  setCategories: (category) => { },
  editCategories: (category) => { },
  deleteCategories: (category) => { },
  features: [],
  products: [],
  setProducts: (product) => { },
  setFeatures: (feature) => { },
  editFeatures: (feature) => { },
  deleteFeatures: (feature) => { },
  setOptions: (option) => { },
  setCodes: (code) => { },
  deleteCode: (code) => { },
  addOrders: (order) => { },
  editOptions: (option) => { },
  deleteOptions: (option) => { },
  options: [],
  markets: [],
  codes: [],
  orders: [],
  counter: 0,
  setOrders: (order) => { },
  editOrders: (order) => { },
  selectedMarket: null,
  loaderOrders: false,
  loaderCodes: false,
  loaderCategory: false,
  loaderOption: false,
  setMarkets: (markets) => { },
  editMarkets: (markets) => { },
  setSelected: (market) => { },
  deleteMarket: (market) => { },
});

function authReducer(state, action) {
  switch (action.type) {
    case "LOGIN":
      return {
        ...state,
        user: action.payload,
      };
    case "LOGOUT":
      return {
        ...state,
        user: null,
      };
    case "SET_CATEGORIES":
      return {
        ...state,
        categories: action.payload,
        loaderCategory: true,
      };
    case "EDIT_CATEGORY":
      const categories = state.categories.map((c) => {
        if (c._id !== action.payload._id) {
          return c;
        } else {
          return action.payload;
        }
      });
      return {
        ...state,
        categories: [...categories],
      };
    case "DELETE_CATEGORY":
      const categoriesFilter = state.categories.filter(
        (c) => c._id !== action.payload._id
      );
      return {
        ...state,
        categories: categoriesFilter,
      };
    case "SET_FEATURES":
      return {
        ...state,
        features: action.payload,
        loaderCategory: true,
      };
    case "EDIT_FEATURES":
      const features = state.features.map((c) => {
        if (c._id !== action.payload._id) {
          return c;
        } else {
          return action.payload;
        }
      });
      return {
        ...state,
        features: [...features],
      };
    case "DELETE_FEATURES":
      const featuresFilter = state.features.map((c) => {
        if (c._id !== action.payload._id) {
          return c;
        } else {
          return action.payload;
        }
      });
      return {
        ...state,
        features: featuresFilter,
      };
    case "SET_CODES":
      return {
        ...state,
        codes: action.payload,
        loaderCodes: true,
      };
    case "DELETE_CODE":
      const codesFilter = state.codes.filter(
        (c) => c._id !== action.payload._id
      );
      return {
        ...state,
        codes: codesFilter,
      };
    case "SET_OPTION":
      return {
        ...state,
        options: action.payload,
        loaderOption: true,
      };
    case "EDIT_OPTION":
      const options = state.options.map((c) => {
        if (c._id !== action.payload._id) {
          return c;
        } else {
          return action.payload;
        }
      });
      return {
        ...state,
        options,
      };
    case "DELETE_OPTION":
      const optionsFilter = state.options.filter(
        (c) => c._id !== action.payload._id
      );
      return {
        ...state,
        options: optionsFilter,
      };
    case "DELETE_MARKET":
      const marketFilter = state.markets.filter(
        (c) => c._id !== action.payload._id
      );
      return {
        ...state,
        markets: marketFilter,
      };
    case "SET_MARKETS":
      let selected;
      if (state.user.market) {
        selected = action.payload.find((m) => m._id === state.user.market);
      }
      if (!selected) {
        return {
          ...state,
          markets: action.payload,
          selectedMarket: action.payload[0],
        };
      } else {
        return {
          ...state,
          markets: action.payload,
          selectedMarket: selected,
        };
      }
    case "SET_SELECTED":
      return {
        ...state,
        selectedMarket: action.payload,
      };
    case "EDIT_MARKET":
      const markets = state.markets.map((c) => {
        if (c._id !== action.payload._id) {
          return c;
        } else {
          return action.payload;
        }
      });
      return {
        ...state,
        markets,
        selectedMarket: action.payload,
      };
    case "SET_PRODUCT":
      return {
        ...state,
        products: action.payload,
      };
    case "SET_ORDERS":
      return {
        ...state,
        orders: action.payload,
        loaderOrders: true,
      };
    case "ADD_ORDERS":
      const ordersV2 = state.orders.filter((c) => {
        if (c._id !== action.payload._id) {
          return true;
        } else {
          return false;
        }
      });
      if (action.payload.market === state.selectedMarket._id) {
        const findered = state.orders.find((c) => c._id === action.payload._id);
        if (!findered) {
          return {
            ...state,
            orders: [action.payload, ...ordersV2],
            counter: state.counter + 1,
          };
        } else {
          return {
            ...state,
            orders: [action.payload, ...ordersV2],
          };
        }
      } else {
        return {
          ...state,
        };
      }
    case "EDIT_ORDER":
      const orders = state.orders.map((c) => {
        if (c._id !== action.payload._id) {
          return c;
        } else {
          return action.payload;
        }
      });
      return {
        ...state,
        orders,
      };
    default:
      return state;
  }
}

function AuthProvider(props) {
  const [state, dispatch] = useReducer(authReducer, initialState);

  function login(userData) {
    localStorage.setItem("jwtToken", userData.token);
    dispatch({
      type: "LOGIN",
      payload: userData,
    });

    window.location.href = "/";
  }

  function editOptions(option) {
    dispatch({
      type: "EDIT_OPTION",
      payload: option,
    });
  }
  function deleteOptions(option) {
    dispatch({
      type: "DELETE_OPTION",
      payload: option,
    });
  }
  function setOrders(order) {
    dispatch({
      type: "SET_ORDERS",
      payload: order,
    });
  }
  function setCodes(codes) {
    dispatch({
      type: "SET_CODES",
      payload: codes,
    });
  }
  function deleteCode(code) {
    dispatch({
      type: "DELETE_CODE",
      payload: code,
    });
  }
  function setOptions(option) {
    dispatch({
      type: "SET_OPTION",
      payload: option,
    });
  }
  function addOrders(order) {
    dispatch({
      type: "ADD_ORDERS",
      payload: order,
    });
  }
  function setSelected(market) {
    dispatch({
      type: "SET_SELECTED",
      payload: market,
    });
  }

  function setMarkets(markets) {
    dispatch({
      type: "SET_MARKETS",
      payload: markets,
    });
  }
  function editMarkets(markets) {
    dispatch({
      type: "EDIT_MARKET",
      payload: markets,
    });
  }
  function deleteMarket(market) {
    dispatch({
      type: "DELETE_MARKET",
      payload: market,
    });
  }
  function setCategories(option) {
    dispatch({
      type: "SET_CATEGORIES",
      payload: option,
    });
  }
  function editCategories(category) {
    dispatch({
      type: "EDIT_CATEGORY",
      payload: category,
    });
  }
  function deleteCategories(category) {
    dispatch({
      type: "DELETE_CATEGORY",
      payload: category,
    });
  }
  function setFeatures(option) {
    dispatch({
      type: "SET_FEATURES",
      payload: option,
    });
  }
  function editFeatures(feature) {
    dispatch({
      type: "EDIT_FEATURES",
      payload: feature,
    });
  }
  function deleteFeatures(feature) {
    dispatch({
      type: "DELETE_FEATURES",
      payload: feature,
    });
  }
  function setProducts(product) {
    dispatch({
      type: "SET_PRODUCT",
      payload: product,
    });
  }
  function logout() {
    localStorage.removeItem("jwtToken");
    dispatch({ type: "LOGOUT" });
    window.location.href = "/login";
  }

  function editOrders(order) {
    dispatch({
      type: "EDIT_ORDER",
      payload: order,
    });
  }

  return (
    <AuthContext.Provider
      value={{
        user: state.user,
        login,
        logout,
        setCategories,
        categories: state.categories,
        markets: state.markets,
        selectedMarket: state.selectedMarket,
        options: state.options,
        codes: state.codes,
        setCodes,
        deleteCode,
        setMarkets,
        editCategories,
        deleteCategories,
        setFeatures,
        editFeatures,
        deleteFeatures,
        setOptions,
        editOptions,
        deleteOptions,
        setProducts,
        setOrders,
        editOrders,
        editMarkets,
        deleteMarket,
        setSelected,
        addOrders,
        orders: state.orders,
        loaderOrders: state.loaderOrders,
        loaderCategory: state.loaderCategory,
        loaderOption: state.loaderOption,
        loaderCodes: state.loaderCodes,
        features: state.features,
        products: state.products,
        counter: state.counter,
      }}
      {...props}
    />
  );
}

export { AuthContext, AuthProvider };
