import React, { useState } from "react";
import { Box, Button, Typography } from "@mui/material";
import {
  Article,
  Discount,
  DiscountRounded,
  EventSeat,
  Group,
  NotificationAdd,
  NotificationsActive,
} from "@mui/icons-material";
import User from "./User";
import Orders from "./Orders";
import Codes from "../Codes";

export default function Selector() {
  const [screen, setScreen] = useState();
  if (!screen) {
    return (
      <Box
        display="flex"
        height="80vh"
        alignItems="center"
        justifyContent="space-around"
      >
        <Button onClick={() => setScreen("ORDERS")} color="inherit">
          <Box
            style={{ backgroundColor: "#EBEBEB", padding: 20, borderRadius: 14 }}
            display="flex"
            flexDirection="column"
            justifyContent="center"
            alignItems="center"
          >
            <Discount style={{ fontSize: 98 }} />
            <Typography typography={{ fontSize: 20 }}>
              {" "}
              Crear Descuento o promoción
            </Typography>
          </Box>
        </Button>
        <Button disabled onClick={() => setScreen("USER")} color="inherit">
          <Box
            style={{ backgroundColor: "#EBEBEB", padding: 20, borderRadius: 14 }}
            display="flex"
            flexDirection="column"
            justifyContent="center"
            alignItems="center"
          >
            <EventSeat style={{ fontSize: 98 }} />
            <Typography typography={{ fontSize: 20 }}>
              Solicitar evento
            </Typography>
          </Box>
        </Button>
        <Button disabled onClick={() => setScreen("USER")} color="inherit">
          <Box
            style={{ backgroundColor: "#EBEBEB", padding: 20, borderRadius: 14 }}
            display="flex"
            flexDirection="column"
            justifyContent="center"
            alignItems="center"
          >
            <NotificationAdd style={{ fontSize: 98 }} />
            <Typography typography={{ fontSize: 20 }}>
              {" "}
              Crear notificación
            </Typography>
          </Box>
        </Button>
        <Button disabled onClick={() => setScreen("USER")} color="inherit">
          <Box
            style={{ backgroundColor: "#EBEBEB", padding: 20, borderRadius: 14 }}
            display="flex"
            flexDirection="column"
            justifyContent="center"
            alignItems="center"
          >
            <NotificationsActive style={{ fontSize: 98 }} />
            <Typography typography={{ fontSize: 20 }}>
              Notificación por proximidad
            </Typography>
          </Box>
        </Button>
      </Box>
    );
  } else {
    return <Codes handleExit={() => setScreen(null)} />;
  }
}
