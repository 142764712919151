import React, { useEffect, useContext, useState, Fragment } from "react";
import {
  Box,
  Button,
  Card,
  CardActions,
  CardContent,
  CardMedia,
  CircularProgress,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  FormControl,
  FormControlLabel,
  FormGroup,
  Grid,
  Input,
  InputLabel,
  MenuItem,
  Modal,
  TextareaAutosize,
  Select,
  Switch,
  TextField,
  Typography,
} from "@mui/material";
import axios from "axios";
import { styled, useTheme } from "@mui/material/styles";

import { consumeGet, URL } from "../utils/constants";
import { AuthContext } from "../context";
import Option from "./Option.png";
import {
  Add,
  AssignmentLate,
  AttachFile,
  Delete,
  Edit,
  SetMealRounded,
} from "@mui/icons-material";
import "react-responsive-carousel/lib/styles/carousel.min.css"; // requires a loader
import { Carousel } from "react-responsive-carousel";
import { Link, useParams } from "react-router-dom";
import { serialize } from "object-to-formdata";
import { isArray } from "lodash";

const IOSSwitch = styled((props) => (
  <Switch focusVisibleClassName=".Mui-focusVisible" disableRipple {...props} />
))(({ theme }) => ({
  width: 42,
  height: 26,
  padding: 0,
  "& .MuiSwitch-switchBase": {
    padding: 0,
    margin: 2,
    transitionDuration: "300ms",
    "&.Mui-checked": {
      transform: "translateX(16px)",
      color: "#fff",
      "& + .MuiSwitch-track": {
        backgroundColor: theme.palette.mode === "dark" ? "#2ECA45" : "#65C466",
        opacity: 1,
        border: 0,
      },
      "&.Mui-disabled + .MuiSwitch-track": {
        opacity: 0.5,
      },
    },
    "&.Mui-focusVisible .MuiSwitch-thumb": {
      color: "#33cf4d",
      border: "6px solid #fff",
    },
    "&.Mui-disabled .MuiSwitch-thumb": {
      color:
        theme.palette.mode === "light"
          ? theme.palette.grey[100]
          : theme.palette.grey[600],
    },
    "&.Mui-disabled + .MuiSwitch-track": {
      opacity: theme.palette.mode === "light" ? 0.7 : 0.3,
    },
  },
  "& .MuiSwitch-thumb": {
    boxSizing: "border-box",
    width: 22,
    height: 22,
  },
  "& .MuiSwitch-track": {
    borderRadius: 26 / 2,
    backgroundColor: theme.palette.mode === "light" ? "#E9E9EA" : "#39393D",
    opacity: 1,
    transition: theme.transitions.create(["background-color"], {
      duration: 500,
    }),
  },
}));

const ProductCard = ({
  data,
  handleEdit,
  handleDelete,
  turnOffOn,
  addPosition,
  loading,
}) => {
  let newData = [[]];
  let counter = 1;
  let index = 0;
  let firstTime = false;
  data.forEach((d) => {
    if (counter === 1 && firstTime) {
      newData.push([]);
    } else {
      if (!firstTime) {
        firstTime = true;
      }
    }
    newData[index].push(d);
    counter++;
    if (counter > 11) {
      index++;
      counter = 1;
    }
  });
  return (
    <Carousel showArrows={true} showStatus={false}>
      {newData.map((d, i) => (
        <Grid style={{ paddingBottom: 30 }} container key={i}>
          {d.map((c, index) => (
            <Grid key={index} lg={2} md={3} sm={6} xl={3} xs={12}>
              <Box width={"90%"} style={{ marginBottom: 15 }}>
                <Card style={{ borderRadius: 20, position: "relative" }}>
                  <CardMedia
                    component="img"
                    height="150"
                    image={URL + c.image}
                    alt={c.name}
                  />
                  <div
                    style={{
                      position: "absolute",
                      top: 0,
                      paddingLeft: 10,
                      paddingRight: 10,
                      display: "flex",
                      justifyContent: "space-between",
                      width: "100%",
                    }}
                  >
                    <Button
                      disabled={loading}
                      onClick={() => addPosition(c._id, "NEXT")}
                      style={{
                        fontSize: 28,
                        color: "white",
                        fontWeight: "800",
                      }}
                    >
                      {"<"}
                    </Button>
                    <Button
                      disabled={loading}
                      onClick={() => addPosition(c._id, "BACK")}
                      style={{
                        fontSize: 28,
                        color: "white",
                        fontWeight: "800",
                      }}
                    >
                      {">"}
                    </Button>
                  </div>
                  <CardActions>
                    <Box
                      display="flex"
                      justifyContent="space-between"
                      alignItems="center"
                      width="100%"
                    >
                      <Typography>{c.name}</Typography>
                      <Box>
                        <Switch
                          checked={c.status}
                          onChange={() => turnOffOn(c)}
                        />
                        <Button
                          onClick={() => handleEdit(c)}
                          style={{ margin: 0, padding: 0, minWidth: 32 }}
                        >
                          <Edit style={{ color: "black" }} />
                        </Button>
                        <Button
                          onClick={() => handleDelete(c._id)}
                          style={{ margin: 0, padding: 0, minWidth: 32 }}
                        >
                          <Delete style={{ color: "black" }} />
                        </Button>
                      </Box>
                    </Box>
                  </CardActions>
                </Card>
              </Box>
            </Grid>
          ))}
        </Grid>
      ))}
    </Carousel>
  );
};

export default function Products() {
  const theme = useTheme();
  const { selectedMarket, categories, editCategories, options, setCategories } =
    useContext(AuthContext);
  const [selectedType, setSelectedType] = useState("");
  const [errors, setErrors] = useState(null);
  const { category } = useParams();
  const [add, handleAdd] = useState({
    open: false,
    product: {
      name: "",
      image: null,
      description: "",
      price: 0,
      option_group: [
        { name: "", min: 0, max: 0, option: "", isMultiple: false },
      ],
    },
    edit: false,
  });
  const [deleteModal, handleDeleteModal] = useState(null);
  const [loading, setLoading] = useState(false);
  const [selectedCategory, handleSelectedCategory] = useState(null);
  const [products, setProducts] = useState([]);

  const addPosition = async (id, type) => {
    setLoading(true);
    try {
      const catId = selectedCategory._id;
      const config = {
        headers: {
          Authorization: "Bearer " + localStorage.getItem("jwtToken"),
          "Access-Control-Allow-Origin": "*",
        },
      };
      const result = await axios.put(
        `${URL}order/${id}`,
        { category: catId, type },
        config
      );
      if (result.status === 200) {
        if (isArray(result.data.category)) {
          result.data.category.forEach((e) => {
            editCategories(e);
          });
        } else {
          editCategories(result.data.category);
        }
      } else {
        console.log(result);
      }
      setLoading(false);
    } catch (err) {
      setLoading(false);
      console.log(err);
    }
  };

  useEffect(() => {
    handleSelectedCategory(categories.find((c) => c._id === category));
  }, [categories, category]);
  useEffect(() => {
    if (selectedCategory) {
      setProducts(selectedCategory.products);
    }
  }, [selectedCategory]);
  if (!selectedCategory)
    return (
      <Box
        width="100%"
        height={400}
        display="flex"
        flexDirection="column"
        justifyContent="center"
        alignItems="center"
      >
        <AssignmentLate style={{ fontSize: 92 }} />
        <Typography>No tienes productos creados</Typography>
      </Box>
    );
  const turnOffOn = async (product) => {
    try {
      const { _id } = product;
      const result = await axios.put(
        `${URL}statusProduct/${_id}`,
        {},
        {
          headers: {
            Authorization: "Bearer " + localStorage.getItem("jwtToken"),
            "Access-Control-Allow-Origin": "*",
          },
        }
      );
      if (result.status === 200) {
        if (isArray(result.data.category)) {
          result.data.category.forEach((e) => {
            editCategories(e);
          });
        } else {
          editCategories(result.data.category);
        }
        handleAdd({
          open: false,
          product: {
            name: "",
            image: null,
            description: "",
            price: 0,
            option_group: [
              { name: "", min: 0, max: 0, option: "", isMultiple: false },
            ],
          },
          edit: false,
        });
      } else {
        setErrors(result.data);
      }
    } catch (err) {
      setErrors(err.response.data.errors);
    }
  };
  const handleConfirm = async () => {
    try {
      setLoading(true);
      const { photo, name, _id, option_group, price, description } =
        add.product;
      const formData = new FormData();
      formData.append("market", selectedMarket._id);
      formData.append("image", photo);
      if (!add.edit) {
        formData.append("category", category);
      }
      if (option_group.length === 1 && option_group[0].name === "") {
        serialize({ name, price, description }, { indices: true }, formData);
      } else {
        serialize(
          { name, options: option_group, price, description },
          { indices: true },
          formData
        );
      }
      const config = {
        headers: {
          "content-type": "multipart/form-data",
          Authorization: "Bearer " + localStorage.getItem("jwtToken"),
          "Access-Control-Allow-Origin": "*",
        },
      };

      let result = null;
      if (add.edit) {
        if (photo) {
          result = await axios.put(`${URL}products/${_id}`, formData, config);
        } else {
          result = await axios.put(
            `${URL}products/${_id}`,
            { name, price, description, options: option_group },
            {
              headers: {
                Authorization: "Bearer " + localStorage.getItem("jwtToken"),
                "Access-Control-Allow-Origin": "*",
              },
            }
          );
        }
      } else {
        result = await axios.post(`${URL}products`, formData, config);
      }
      if (result.status === 200) {
        setErrors(null);
        if (isArray(result.data.category)) {
          result.data.category.forEach((e) => {
            editCategories(e);
          });
        } else {
          editCategories(result.data.category);
        }
        handleAdd({
          open: false,
          product: {
            name: "",
            image: null,
            description: "",
            price: 0,
            option_group: [
              { name: "", min: 0, max: 0, option: "", isMultiple: false },
            ],
          },
          edit: false,
        });
      } else {
        setErrors(result.data);
      }
      setLoading(false);
    } catch (err) {
      setLoading(false);
      setErrors(err.response.data.errors);
    }
  };
  const handleErase = async () => {
    const config = {
      headers: {
        Authorization: "Bearer " + localStorage.getItem("jwtToken"),
      },
    };

    const result = await axios.delete(
      `${URL}products/${category}/${deleteModal}`,
      config
    );
    handleDeleteModal(null);
    if (result.status === 200) {
      editCategories(result.data.category);
    } else {
      console.log("errors");
    }
  };
  return (
    <Box>
      <Dialog fullWidth maxWidth="md" open={add.open}>
        {loading ? (
          <DialogContent>
            <Box
              style={{
                paddingTop: 80,
                paddingBottom: 80,
                display: "flex",
                justifyContent: "center",
                alignItems: "center",
              }}
            >
              <CircularProgress size={70} />
            </Box>
          </DialogContent>
        ) : (
          <Fragment>
            <DialogTitle>Añadir producto</DialogTitle>
            <DialogContent>
              <Box
                marginBottom={2}
                paddingRight={3}
                display="flex"
                justifyContent={"space-between"}
              >
                <Box display="flex" alignItems="center" width={"100%"}>
                  <Box width="15%">
                    <Typography>Nombre</Typography>
                  </Box>
                  <Box width={"85%"}>
                    <TextField
                      style={{
                        marginLeft: 44,
                        boxShadow: "rgb(116 116 116) 1px 0px 1px",
                        borderRadius: 4,
                      }}
                      fullWidth
                      inputProps={{ style: { borderRadius: 18 } }}
                      value={add.product.name}
                      onChange={(e) =>
                        handleAdd({
                          ...add,
                          product: { ...add.product, name: e.target.value },
                        })
                      }
                    />
                  </Box>
                </Box>
                <Box
                  display="flex"
                  alignItems="center"
                  marginLeft={5}
                  width={"85%"}
                >
                  <Box width="30%" marginLeft={10}>
                    <Typography style={{ textAlign: "center" }}>
                      Precio sin Iva
                    </Typography>
                  </Box>
                  <Box width="80%" display="flex" alignItems="center">
                    <Typography marginRight={-2}>$</Typography>
                    <TextField
                      value={add.product.price}
                      type="number"
                      onChange={(e) => {
                        handleAdd({
                          ...add,
                          product: { ...add.product, price: e.target.value },
                        });
                      }}
                      style={{
                        marginLeft: 24,
                        boxShadow: "rgb(116 116 116) 1px 2px 4px",
                        borderRadius: 4,
                      }}
                    />
                  </Box>
                </Box>
              </Box>
              <Box marginBottom={2}>
                <Box display="flex" alignItems="center">
                  <Box width="15%">
                    <Typography>Descripcion</Typography>
                  </Box>
                  <Box width="85%">
                    <TextField
                      style={{
                        marginLeft: -23,
                        boxShadow: "rgb(116 116 116) 1px 0px 1px",
                        borderRadius: 4,
                      }}
                      fullWidth
                      multiline
                      minRows={2}
                      inputProps={{
                        maxLength: 100,
                      }}
                      value={add.product.description}
                      onChange={(e) =>
                        handleAdd({
                          ...add,
                          product: {
                            ...add.product,
                            description: e.target.value,
                          },
                        })
                      }
                    />
                  </Box>
                </Box>
              </Box>
              <Box marginBottom={2}></Box>
              <Box
                marginBottom={2}
                paddingRight={3.7}
                display={"flex"}
                justifyContent={"space-between"}
              >
                <Box display="flex" alignItems="center">
                  <Box width="50">
                    <Typography>Añadir Foto</Typography>
                  </Box>
                  <Box>
                    <Button
                      style={{ marginLeft: 24 }}
                      variant="outlined"
                      component="label"
                    >
                      <AttachFile />
                      <input
                        onChange={(e) =>
                          handleAdd({
                            ...add,
                            product: {
                              ...add.product,
                              photo: e.target.files[0],
                            },
                          })
                        }
                        type="file"
                        hidden
                      />
                    </Button>
                  </Box>
                </Box>
                <Box display="flex"width={350} alignItems="center" >
                  <Box>
                    <Typography>Añadir tipo de producto</Typography>
                  </Box>
                  <Box width={"100%"}>
                    <FormControl sx={{ m: 1, width: "100%" }}>
                      {console.log(selectedType)}
                      <InputLabel id="select">Lista</InputLabel>
                      <Select
                        labelId="select"
                        id="demo-simple-select-helper"
                        value={selectedType}
                        fullWidth
                        label="Option List Group"
                        onChange={(e) => {
                          setSelectedType(e.target.value);
                        }}
                      >
                        <MenuItem
                          selected={selectedType === "Comidas"}
                          value={"Comidas"}
                        >
                          Comidas
                        </MenuItem>
                        <MenuItem
                          selected={selectedType === "Bebidas"}
                          value={"Bebidas"}
                        >
                          Bebidas
                        </MenuItem>
                      </Select>
                    </FormControl>
                  </Box>
                </Box>
              </Box>
              <Typography
                style={{
                  marginBottom: 20,
                  fontWeight: 800,
                  textAlign: "center",
                }}
              >
                Pasos para armar el producto
              </Typography>
              {add.product.option_group.map((o, i) => (
                <Grid
                  style={{
                    marginBottom: 20,
                    justifyContent: "center",
                    alignItems: "center",
                  }}
                  container
                >
                  <Grid xs={1}>
                    <Button
                      style={{
                        height: "100%",
                        width: "100%",
                        display: "flex",
                        justifyContent: "center",
                        alignItems: "center",
                        fontWeight: "bold",
                        color: "black",
                      }}
                      onClick={() => {
                        let option_group = add.product.option_group;
                        if (option_group.length > 1) {
                          option_group.splice(i, 1);
                        }
                        handleAdd({
                          ...add,
                          product: { ...add.product, option_group },
                        });
                      }}
                      disabled={add.onlySee}
                    >
                      <Typography fontSize={18} textAlign="center">
                        X
                      </Typography>
                    </Button>
                  </Grid>
                  <Grid
                    xs={1}
                    style={{
                      width: "100%",
                      display: "flex",
                      justifyContent: "center",
                      alignItems: "center",
                      fontWeight: "bold",
                      color: "black",
                    }}
                  >
                    <Typography fontSize={20} textAlign="center">
                      {i + 1}
                    </Typography>
                  </Grid>
                  <Grid xs={4}>
                    <Box width="90%">
                      <TextField
                        disabled={add.onlySee}
                        style={{
                          marginLeft: 24,
                          boxShadow: "rgb(116 116 116) 1px 2px 4px",
                          borderRadius: 4,
                        }}
                        placeholder="name"
                        value={o.name}
                        fullWidth
                        onChange={(e) => {
                          const option_group = add.product.option_group;
                          option_group[i] = {
                            ...option_group[i],
                            name: e.target.value,
                          };
                          handleAdd({
                            ...add,
                            product: {
                              ...add.product,
                              option_group: option_group,
                            },
                          });
                        }}
                      />
                    </Box>
                  </Grid>
                  <Grid xs={4}>
                    <Box
                      width="90%"
                      display="flex"
                      justifyContent="center"
                      alignItems="center"
                    >
                      <FormControl sx={{ m: 1, width: "100%" }}>
                        <InputLabel id="select">Lista de opción</InputLabel>
                        <Select
                          labelId="select"
                          id="demo-simple-select-helper"
                          value={o.option}
                          fullWidth
                          label="Option List Group"
                          onChange={(e) => {
                            const option_group = add.product.option_group;
                            option_group[i] = {
                              ...option_group[i],
                              option: e.target.value,
                            };
                            handleAdd({
                              ...add,
                              product: {
                                ...add.product,
                                option_group: option_group,
                              },
                            });
                          }}
                        >
                          {options
                            .sort((a, b) => (a.name > b.name ? 1 : -1))
                            .map((option, io) => (
                              <MenuItem
                                selected={option._id === o.option}
                                key={io}
                                value={option._id}
                              >
                                {option.name}
                              </MenuItem>
                            ))}
                        </Select>
                      </FormControl>
                    </Box>
                  </Grid>

                  <Grid xs={3}>
                    <Box
                      width="90%"
                      display="flex"
                      justifyContent="center"
                      alignItems="center"
                    >
                      <Typography style={{ marginRight: 8 }} id="min">
                        Min
                      </Typography>
                      <TextField
                        labelId="min"
                        id="min"
                        value={o.min}
                        type="number"
                        onChange={(e) => {
                          const option_group = add.product.option_group;
                          option_group[i] = {
                            ...option_group[i],
                            min: e.target.value > 0 ? e.target.value : 0,
                          };
                          handleAdd({
                            ...add,
                            product: {
                              ...add.product,
                              option_group: option_group,
                            },
                          });
                        }}
                      />
                    </Box>
                  </Grid>
                  <Grid xs={3}>
                    <Box
                      width="90%"
                      display="flex"
                      justifyContent="center"
                      alignItems="center"
                    >
                      <Typography style={{ marginRight: 8 }} id="max">
                        Max
                      </Typography>
                      <TextField
                        labelId="max"
                        id="max"
                        value={o.max}
                        type="number"
                        onChange={(e) => {
                          const option_group = add.product.option_group;
                          option_group[i] = {
                            ...option_group[i],
                            max: e.target.value > 0 ? e.target.value : 0,
                          };
                          handleAdd({
                            ...add,
                            product: {
                              ...add.product,
                              option_group: option_group,
                            },
                          });
                        }}
                      />
                    </Box>
                  </Grid>
                  <Grid xs={3}>
                    <Box
                      width="90%"
                      display="flex"
                      justifyContent="center"
                      alignItems="center"
                    >
                      <Typography style={{ marginRight: 8 }} id="max">
                        Multiple
                      </Typography>
                      <IOSSwitch
                        checked={o.isMultiple}
                        onChange={(e) => {
                          const option_group = add.product.option_group;
                          option_group[i] = {
                            ...option_group[i],
                            isMultiple: !o.isMultiple,
                          };
                          handleAdd({
                            ...add,
                            product: {
                              ...add.product,
                              option_group: option_group,
                            },
                          });
                        }}
                      />
                    </Box>
                  </Grid>
                </Grid>
              ))}
              <Box
                display="flex"
                style={{ marginTop: 20 }}
                justifyContent="flex-end"
                alignItems="flex-end"
              >
                <Button
                  variant="outlined"
                  style={{
                    backgroundColor: "white",
                    boxShadow: "rgb(116 116 116) 1px 2px 4px",
                  }}
                  disabled={loading}
                  onClick={() => {
                    let actual = add.product.option_group;
                    actual.push({
                      name: "",
                      min: 0,
                      max: 0,
                      option: "",
                      isMultiple: false,
                    });
                    handleAdd({
                      ...add,
                      product: { ...add.product, option_group: actual },
                    });
                  }}
                >
                  <Typography>Añadir</Typography>
                  <Add />
                </Button>
              </Box>
              {errors && (
                <Card style={{ width: "80%", marginLeft: "10%" }}>
                  <CardContent>
                    {Object.values(errors).map((e) => (
                      <Typography color="#df4759" textAlign="center">
                        *{e}
                      </Typography>
                    ))}
                  </CardContent>
                </Card>
              )}
            </DialogContent>
            <DialogActions>
              <Button
                color="inherit"
                onClick={() =>
                  handleAdd({
                    open: false,
                    product: {
                      name: "",
                      image: null,
                      description: "",
                      price: 0,
                      option_group: [
                        {
                          name: "",
                          min: 0,
                          max: 0,
                          option: "",
                          isMultiple: false,
                        },
                      ],
                    },
                    edit: false,
                  })
                }
                variant="contained"
              >
                Cancelar
              </Button>
              <Button onClick={() => handleConfirm()} variant="contained">
                Ok
              </Button>
            </DialogActions>
          </Fragment>
        )}
      </Dialog>
      <Dialog open={deleteModal ? true : false}>
        <Box style={{ padding: 12 }}>
          <DialogTitle
            style={{
              marginBottom: 20,
              textAlign: "center",
              fontSize: 20,
              fontWeight: 800,
            }}
          >
            Eliminar producto
          </DialogTitle>
          <DialogContent>
            <Typography style={{ marginBottom: 12 }}>
              ¿Estas seguro? Perderas toda información sobre este producto
            </Typography>
          </DialogContent>
          <DialogActions>
            <Button
              color="inherit"
              onClick={() => handleDeleteModal(null)}
              variant="contained"
              style={{ width: "50%" }}
            >
              Cancelar
            </Button>
            <Button
              style={{ width: "50%" }}
              onClick={() => handleErase()}
              variant="contained"
            >
              Ok
            </Button>
          </DialogActions>
        </Box>
      </Dialog>
      <Box
        display="flex"
        justifyContent="space-between"
        alignItems="center"
        width="100%"
        style={{ marginBottom: 12 }}
      >
        <Box display="flex">
          <Link to="/categories" style={{ textDecoration: "none" }}>
            <Typography
              fontSize={25}
              color={theme.palette.primary.main}
              fontWeight={800}
              variant="h2"
              style={{ marginRight: 8 }}
            >
              {"<"}
            </Typography>
          </Link>
          <Link to="/categories" style={{ textDecoration: "none" }}>
            <Typography
              fontSize={25}
              color="#5a5959"
              fontWeight={800}
              variant="h2"
            >
              Categorias
            </Typography>
          </Link>
          <Typography
            fontSize={25}
            color={theme.palette.primary.main}
            fontWeight={800}
            variant="h2"
            style={{ paddingLeft: 8, paddingRight: 8 }}
          >
            {">"}
          </Typography>
          <Typography
            fontSize={25}
            color="#5a5959"
            fontWeight={800}
            variant="h2"
          >
            {selectedCategory.name}
          </Typography>
        </Box>
        <Box>
          <Button
            variant="outlined"
            style={{
              backgroundColor: "white",
              boxShadow: "rgb(116 116 116) 1px 2px 4px",
              borderRadius: 12,
            }}
            onClick={() =>
              handleAdd({
                open: true,
                product: {
                  name: "",
                  image: null,
                  description: "",
                  price: 0,
                  option_group: [
                    { name: "", min: 0, max: 0, option: "", isMultiple: false },
                  ],
                },
                edit: false,
              })
            }
          >
            <Typography>Añadir</Typography>
            <Add />
          </Button>
        </Box>
      </Box>
      <Box>
        {products.length > 0 ? (
          <ProductCard
            loading={loading}
            data={products.sort((a, b) => (a.order > b.order ? 1 : -1))}
            addPosition={addPosition}
            handleEdit={(handler) =>
              handleAdd({
                open: true,
                product: {
                  ...handler,
                  option_group: handler.option_group.map((p) => {
                    const Objeto = p;
                    Objeto.option = p.option._id;
                    return Objeto;
                  }),
                },
                edit: true,
              })
            }
            turnOffOn={turnOffOn}
            handleDelete={(handler) => handleDeleteModal(handler)}
          />
        ) : (
          <Box
            width="100%"
            height={400}
            display="flex"
            flexDirection="column"
            justifyContent="center"
            alignItems="center"
          >
            <AssignmentLate style={{ fontSize: 92 }} />
            <Typography>No hay productos creados</Typography>
          </Box>
        )}
      </Box>
    </Box>
  );
}
