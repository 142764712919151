import React, { Fragment, useContext, useEffect, useState } from "react";
import { styled, useTheme } from "@mui/material/styles";
import Box from "@mui/material/Box";
import Drawer from "@mui/material/Drawer";
import CssBaseline from "@mui/material/CssBaseline";
import MuiAppBar from "@mui/material/AppBar";
import Toolbar from "@mui/material/Toolbar";
import List from "@mui/material/List";
import Typography from "@mui/material/Typography";
import Divider from "@mui/material/Divider";
import ShoppingBagIcon from "@mui/icons-material/ShoppingBag";
import ListItem from "@mui/material/ListItem";
import ListItemIcon from "@mui/material/ListItemIcon";
import CircularProgress from "@mui/material/CircularProgress";
import CalendarMonthIcon from '@mui/icons-material/CalendarMonth';
import {
  Assignment,
  BarChart,
  Discount,
  Dashboard,
  NotificationAdd,
  ExitToApp,
  History,
  Notifications,
  Restaurant,
  Settings,
  Storefront,
} from "@mui/icons-material";
import {
  alpha,
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  MenuItem,
  Modal,
  Popover,
  Select,
  Switch,
} from "@mui/material";
import { green } from "@mui/material/colors";
import {
  BrowserRouter as Router,
  Link,
  Route,
  Routes,
  useLocation,
} from "react-router-dom";
import Categories from "./Categories";
import Options from "./Options";
import Products from "./Products";
import { consumeGet, URL } from "../utils/constants";
import { AuthContext } from "../context";
import { useNavigate } from "react-router-dom";
import Pending from "./orders/Pending";
import Ready from "./orders/Ready";
import Processed from "./orders/Processed";
import Selector from "./history/Selector";
import Configuration from "./Configuration";
import Features from "./Features";
import socket from "./Socket";
import axios from "axios";
import Codes from "./Codes";
import Promotion from "./Promotions";
import User from "./history/User";
import Reserves from "./history/Reserves";
import Stadistics from "./history/Stadistics";
const drawerWidth = 100;

const Main = styled("main", { shouldForwardProp: (prop) => prop !== "open" })(
  ({ theme, open }) => ({
    flexGrow: 1,
    padding: theme.spacing(3),
    transition: theme.transitions.create("margin", {
      easing: theme.transitions.easing.sharp,
      duration: theme.transitions.duration.leavingScreen,
    }),
    marginLeft: `-${drawerWidth}px`,
    ...(open && {
      transition: theme.transitions.create("margin", {
        easing: theme.transitions.easing.easeOut,
        duration: theme.transitions.duration.enteringScreen,
      }),
      marginLeft: 0,
    }),
  })
);

const AppBar = styled(MuiAppBar, {
  shouldForwardProp: (prop) => prop !== "open",
})(({ theme, open }) => ({
  transition: theme.transitions.create(["margin", "width"], {
    easing: theme.transitions.easing.sharp,
    duration: theme.transitions.duration.leavingScreen,
  }),
  ...(open && {
    width: `calc(100% - ${drawerWidth}px)`,
    marginLeft: `${drawerWidth}px`,
    transition: theme.transitions.create(["margin", "width"], {
      easing: theme.transitions.easing.easeOut,
      duration: theme.transitions.duration.enteringScreen,
    }),
  }),
}));

const DrawerHeader = styled("div")(({ theme }) => ({
  display: "flex",
  alignItems: "center",
  padding: theme.spacing(0, 1),
  // necessary for content to be below app bar
  ...theme.mixins.toolbar,
  justifyContent: "flex-end",
}));
const IOSSwitch = styled((props) => (
  <Switch focusVisibleClassName=".Mui-focusVisible" disableRipple {...props} />
))(({ theme }) => ({
  width: 42,
  height: 26,
  padding: 0,
  "& .MuiSwitch-switchBase": {
    padding: 0,
    margin: 2,
    transitionDuration: "300ms",
    "&.Mui-checked": {
      transform: "translateX(16px)",
      color: "#fff",
      "& + .MuiSwitch-track": {
        backgroundColor: theme.palette.mode === "dark" ? "#2ECA45" : "#65C466",
        opacity: 1,
        border: 0,
      },
      "&.Mui-disabled + .MuiSwitch-track": {
        opacity: 0.5,
      },
    },
    "&.Mui-focusVisible .MuiSwitch-thumb": {
      color: "#33cf4d",
      border: "6px solid #fff",
    },
    "&.Mui-disabled .MuiSwitch-thumb": {
      color:
        theme.palette.mode === "light"
          ? theme.palette.grey[100]
          : theme.palette.grey[600],
    },
    "&.Mui-disabled + .MuiSwitch-track": {
      opacity: theme.palette.mode === "light" ? 0.7 : 0.3,
    },
  },
  "& .MuiSwitch-thumb": {
    boxSizing: "border-box",
    width: 22,
    height: 22,
  },
  "& .MuiSwitch-track": {
    borderRadius: 26 / 2,
    backgroundColor: theme.palette.mode === "light" ? "#E9E9EA" : "#39393D",
    opacity: 1,
    transition: theme.transitions.create(["background-color"], {
      duration: 500,
    }),
  },
}));

const Navigator = () => {
  const theme = useTheme();
  const { selectedMarket, markets, setSelected, user, logout, editMarkets } =
    useContext(AuthContext);
  const open = true;
  const [anchorEl, setAnchorEl] = useState(null);
  const [logoutModal, setLogoutModal] = useState(false);
  const handleClick = (event) => {
    setAnchorEl(event.currentTarget);
  };
  const uploadMarket = async () => {
    try {
      const result = await axios.put(
        `${URL}markets/${selectedMarket._id}`,
        { active: !selectedMarket.active },
        {
          headers: {
            Authorization: "Bearer " + localStorage.getItem("jwtToken"),
            "Access-Control-Allow-Origin": "*",
          },
        }
      );
      if (result.status === 200) {
        editMarkets(result.data);
      } else {
        console.log(result.data);
      }
    } catch (err) {
      console.log(err);
    }
  };
  const handleClose = () => {
    setAnchorEl(null);
  };

  const open2 = Boolean(anchorEl);
  const id = open2 ? "simple-popover" : undefined;
  const [condition, setCondition] = useState(1);
  let navigate = useNavigate();
  const location = useLocation();
  useEffect(() => {
    let conditional = location.pathname.split("/");
    if (conditional[1] === "orders") {
      setCondition(1);
    } else if (conditional[1] === "configuration") {
      setCondition(3);
    } else if (conditional[1] === "stadistics") {
      setCondition(4);
    } else if (conditional[1] === "requests") {
      setCondition(5);
    } else if (conditional[1] === "reserves") {
      setCondition(6);
    } else {
      setCondition(2);
    }
  }, [location]);
  return (
    <Fragment>
      <Dialog open={logoutModal}>
        <Box
          style={{
            padding: 12,
            marginLeft: 40,
            marginRight: 40,
            display: "flex",
            flexDirection: "column",
            justifyContent: "center",
            alignItems: "center",
          }}
        >
          <DialogTitle
            style={{
              marginBottom: 20,
              textAlign: "center",
              fontSize: 20,
              fontWeight: 800,
            }}
          >
            Close Session
          </DialogTitle>
          <DialogContent>
            <Typography style={{ marginBottom: 12 }}>Are you sure?</Typography>
          </DialogContent>
          <DialogActions>
            <Button
              color="inherit"
              onClick={() => setLogoutModal(false)}
              variant="contained"
              style={{ width: "50%" }}
            >
              Cancel
            </Button>
            <Button
              style={{ width: "50%" }}
              onClick={() => logout()}
              variant="contained"
            >
              Ok
            </Button>
          </DialogActions>
        </Box>
      </Dialog>
      <Drawer
        sx={{
          width: drawerWidth,
          flexShrink: 0,
          "& .MuiDrawer-paper": {
            width: drawerWidth,
            boxSizing: "border-box",
            backgroundColor: theme.palette.primary.main,
          },
        }}
        variant="persistent"
        anchor="left"
        open={open}
      >
        <List>
          {user.isSuperAdmin && (
            <ListItem
              aria-describedby={id}
              onClick={handleClick}
              style={{ justifyContent: "center" }}
              button
            >
              <ListItemIcon
                style={{
                  justifyContent: "center",
                  minWidth: 0,
                  marginBottom: 12,
                }}
              >
                <Storefront
                  sx={{ fontSize: 35, textAlign: "center", color: "white" }}
                />
              </ListItemIcon>
            </ListItem>
          )}
          <Popover
            id={id}
            open={open2}
            anchorEl={anchorEl}
            onClose={handleClose}
            anchorOrigin={{
              vertical: "top",
              horizontal: "right",
            }}
          >
            {markets.map((m) => (
              <MenuItem
                style={
                  selectedMarket === m
                    ? {
                        backgroundColor: theme.palette.primary.main,
                        color: "white",
                      }
                    : null
                }
                onClick={() => setSelected(m)}
              >
                {m.name}
              </MenuItem>
            ))}
          </Popover>
          <ListItem style={{ justifyContent: "center" }} button>
            <ListItemIcon style={{ justifyContent: "center", minWidth: 0 }}>
              <IOSSwitch
                checked={selectedMarket.active}
                onChange={uploadMarket}
              />
            </ListItemIcon>
          </ListItem>
        </List>
        <Divider />

        <List
          style={{
            display: "flex",
            flexDirection: "column",
            height: "100%",
            alignItems: "center",
            justifyContent: "space-around",
            padding: 10,
          }}
        >
          <ListItem
            onClick={() => navigate("/categories")}
            style={
              condition === 2
                ? {
                    backgroundColor: "white",
                    color: theme.palette.primary.main,
                    justifyContent: "center",
                    borderRadius: 8,
                  }
                : { justifyContent: "center" }
            }
            button
          >
            <ListItemIcon
              style={{
                justifyContent: "center",
                minWidth: 0,
                flexDirection: "column",
                alignItems: "center",
                padding: 2,
              }}
            >
              <Restaurant
                sx={{
                  fontSize: 35,
                  textAlign: "center",
                  color: condition === 2 ? theme.palette.primary.main : "white",
                }}
              />
              <p
                style={{
                  fontSize: 12,
                  margin: 0,
                  color: condition === 2 ? theme.palette.primary.main : "white",
                }}
              >
                Productos
              </p>
            </ListItemIcon>
          </ListItem>
          <ListItem
            onClick={() => navigate("/reserves")}
            style={
              condition === 6
                ? {
                    backgroundColor: "white",
                    color: theme.palette.primary.main,
                    justifyContent: "center",
                    borderRadius: 8,
                  }
                : { justifyContent: "center" }
            }
            button
          >
            <ListItemIcon
              style={{
                justifyContent: "center",
                minWidth: 0,
                flexDirection: "column",
                alignItems: "center",
              }}
            >
              <CalendarMonthIcon
                sx={{
                  fontSize: 35,
                  textAlign: "center",
                  color: condition === 6 ? theme.palette.primary.main : "white",
                }}
              />
              <p
                style={{
                  fontSize: 12,
                  margin: 0,
                  color: condition === 6 ? theme.palette.primary.main : "white",
                }}
              >
                Reservas
              </p>
            </ListItemIcon>
          </ListItem>
          <ListItem
            onClick={() => navigate("/orders/pending")}
            style={
              condition === 1
                ? {
                    backgroundColor: "white",
                    color: theme.palette.primary.main,
                    justifyContent: "center",
                    borderRadius: 8,
                  }
                : { justifyContent: "center" }
            }
            button
          >
            <ListItemIcon
              style={{
                justifyContent: "center",
                minWidth: 0,
                flexDirection: "column",
                alignItems: "center",
              }}
            >
              <ShoppingBagIcon
                sx={{
                  fontSize: 35,
                  textAlign: "center",
                  color: condition === 1 ? theme.palette.primary.main : "white",
                }}
              />
              <p
                style={{
                  fontSize: 12,
                  margin: 0,
                  color: condition === 1 ? theme.palette.primary.main : "white",
                }}
              >
                Ordenes
              </p>
            </ListItemIcon>
          </ListItem>

          <ListItem
            onClick={() => navigate("/stadistics")}
            style={
              condition === 4
                ? {
                    backgroundColor: "white",
                    color: theme.palette.primary.main,
                    justifyContent: "center",
                    borderRadius: 8,
                  }
                : { justifyContent: "center" }
            }
            button
          >
            <ListItemIcon
              style={{
                justifyContent: "center",
                minWidth: 0,
                flexDirection: "column",
                alignItems: "center",
              }}
            >
              <BarChart
                sx={{
                  fontSize: 35,
                  textAlign: "center",
                  color: condition === 4 ? theme.palette.primary.main : "white",
                }}
              />
              <p
                style={{
                  fontSize: 12,
                  margin: 0,
                  color: condition === 4 ? theme.palette.primary.main : "white",
                }}
              >
                Estadisticas
              </p>
            </ListItemIcon>
          </ListItem>
          <ListItem
            onClick={() => navigate("/requests")}
            style={
              condition === 5
                ? {
                    backgroundColor: "white",
                    color: theme.palette.primary.main,
                    justifyContent: "center",
                    borderRadius: 8,
                  }
                : { justifyContent: "center" }
            }
            button
          >
            <ListItemIcon
              style={{
                justifyContent: "center",
                minWidth: 0,
                flexDirection: "column",
                alignItems: "center",
              }}
            >
              <NotificationAdd
                sx={{
                  fontSize: 35,
                  textAlign: "center",
                  color: condition === 5 ? theme.palette.primary.main : "white",
                }}
              />
              <p
                style={{
                  fontSize: 12,
                  margin: 0,
                  color: condition === 5 ? theme.palette.primary.main : "white",
                }}
              >
                Notificaciones
              </p>
            </ListItemIcon>
          </ListItem>
          <ListItem
            onClick={() => navigate("/codes")}
            style={
              condition === 9
                ? {
                    backgroundColor: "white",
                    color: theme.palette.primary.main,
                    justifyContent: "center",
                    borderRadius: 8,
                  }
                : { justifyContent: "center" }
            }
            button
            disabled
          >
            <ListItemIcon
              style={{
                justifyContent: "center",
                minWidth: 0,
                flexDirection: "column",
                alignItems: "center",
              }}
            >
              <Dashboard
                sx={{
                  fontSize: 35,
                  textAlign: "center",
                  color: condition === 9 ? theme.palette.primary.main : "white",
                }}
              />
              <p
                style={{
                  fontSize: 12,
                  margin: 0,
                  color: condition === 9 ? theme.palette.primary.main : "white",
                }}
              >
                Dashboard
              </p>
            </ListItemIcon>
          </ListItem>

          {user.isSuperAdmin && (
            <ListItem
              onClick={() => navigate("/configuration")}
              style={
                condition === 3
                  ? {
                      backgroundColor: "white",
                      color: theme.palette.primary.main,
                      justifyContent: "center",
                      borderRadius: 8,
                    }
                  : { justifyContent: "center" }
              }
              button
              disabled
            >
              <ListItemIcon
                style={{
                  justifyContent: "center",
                  minWidth: 0,
                  flexDirection: "column",
                  alignItems: "center",
                }}
              >
                <Settings
                  sx={{
                    fontSize: 35,
                    textAlign: "center",
                    color:
                      condition === 3 ? theme.palette.primary.main : "white",
                  }}
                />
                <p
                  style={{
                    fontSize: 12,
                    margin: 0,
                    color:
                      condition === 3 ? theme.palette.primary.main : "white",
                  }}
                >
                  Configuracion
                </p>
              </ListItemIcon>
            </ListItem>
          )}
          <ListItem
            onClick={() => setLogoutModal(true)}
            style={{ justifyContent: "center" }}
            button
          >
            <ListItemIcon
              style={{
                justifyContent: "center",
                minWidth: 0,
                flexDirection: "column",
                alignItems: "center",
              }}
            >
              <ExitToApp
                sx={{ fontSize: 35, textAlign: "center", color: "white" }}
              />
              <p style={{ fontSize: 12, margin: 0, color: "white" }}>Salir</p>
            </ListItemIcon>
          </ListItem>
        </List>
      </Drawer>
    </Fragment>
  );
};

const TbHead = ({ open }) => {
  const location = useLocation();
  let navigate = useNavigate();
  const theme = useTheme();
  const { selectedMarket } = useContext(AuthContext);
  if (
    location.pathname !== "/orders/pending" &&
    location.pathname !== "/orders/toPick" &&
    location.pathname !== "/orders/ready"
  ) {
    return (
      <AppBar position="fixed" open={open}>
        <Toolbar>
          <Typography variant="h6" noWrap component="div">
            {selectedMarket && selectedMarket.name}
          </Typography>
        </Toolbar>
      </AppBar>
    );
  } else {
    return (
      <AppBar position="fixed" open={open}>
        <Toolbar>
          <Box width="100%" display="flex" justifyContent="space-around">
            <Button
              style={
                location.pathname === "/orders/pending"
                  ? { backgroundColor: "white" }
                  : null
              }
              onClick={() => navigate("/orders/pending")}
            >
              <Notifications
                sx={
                  location.pathname === "/orders/pending"
                    ? {
                        fontSize: 35,
                        textAlign: "center",
                        color: theme.palette.primary.main,
                      }
                    : { fontSize: 35, textAlign: "center", color: "white" }
                }
              />
            </Button>
            <Button
              style={
                location.pathname === "/orders/toPick"
                  ? { backgroundColor: "white" }
                  : null
              }
              onClick={() => navigate("/orders/toPick")}
            >
              <History
                sx={
                  location.pathname === "/orders/toPick"
                    ? {
                        fontSize: 35,
                        textAlign: "center",
                        color: theme.palette.primary.main,
                      }
                    : { fontSize: 35, textAlign: "center", color: "white" }
                }
              />
            </Button>
            <Button
              style={
                location.pathname === "/orders/ready"
                  ? { backgroundColor: "white" }
                  : null
              }
              onClick={() => navigate("/orders/ready")}
            >
              <Assignment
                sx={
                  location.pathname === "/orders/ready"
                    ? {
                        fontSize: 35,
                        textAlign: "center",
                        color: theme.palette.primary.main,
                      }
                    : { fontSize: 35, textAlign: "center", color: "white" }
                }
              />
            </Button>
          </Box>
        </Toolbar>
      </AppBar>
    );
  }
};
export default function PersistentDrawerLeft() {
  const theme = useTheme();
  const open = true;
  const [ready, setReady] = useState(false);
  const [modalFirst, setModalFirst] = useState(true);
  const {
    setCategories,
    setOptions,
    setCodes,
    addOrders,
    loaderCodes,
    setOrders,
    setProducts,
    setFeatures,
    user,
    orders,
    selectedMarket,
    loaderCategory,
    editOrders,
    counter,
    loaderOption,
    loaderOrders,
  } = useContext(AuthContext);
  const playSound = new Audio();
  const [first, setFirst] = useState(false);
  const consumeData = async () => {
    try {
      const result = await consumeGet("categories", {
        market: selectedMarket._id,
      });

      if (result.status === 200) {
        setCategories(result.data);
      }
      const result2 = await consumeGet("options", {
        market: selectedMarket._id,
      });

      if (result2.status === 200) {
        setOptions(result2.data);
      } else {
        console.log(result2);
      }

      const result3 = await consumeGet("orders", {
        market: selectedMarket._id,
        onlyAdmin: true,
      });

      if (result3.status === 200) {
        setOrders(result3.data);
      } else {
        console.log(result3);
      }
      const result4 = await consumeGet("featured");

      if (result4.status === 200) {
        setFeatures(result4.data);
      } else {
        console.log(result4);
      }
      const result5 = await consumeGet("products", {
        market: selectedMarket._id,
      });

      if (result5.status === 200) {
        setProducts(result5.data);
      } else {
        console.log(result5);
      }
      const result6 = await consumeGet("codes", {
        market: selectedMarket._id,
      });

      if (result6.status === 200) {
        setCodes(result6.data);
      } else {
        console.log(result6);
      }
    } catch (err) {
      console.log(err);
    }
  };
  useEffect(() => {
    consumeData();
  }, [selectedMarket]);
  useEffect(() => {
    const interval = setInterval(() => {
      console.log(orders);
      if (orders.length > 0) {
        if (orders.filter((o) => o.pickup == "PENDING").length > 0) {
          playSound.src =
            "data:audio/mpeg;base64,SUQzBAAAAAABEVRYWFgAAAAtAAADY29tbWVudABCaWdTb3VuZEJhbmsuY29tIC8gTGFTb25vdGhlcXVlLm9yZwBURU5DAAAAHQAAA1N3aXRjaCBQbHVzIMKpIE5DSCBTb2Z0d2FyZQBUSVQyAAAABgAAAzIyMzUAVFNTRQAAAA8AAANMYXZmNTcuODMuMTAwAAAAAAAAAAAAAAD/80DEAAAAA0gAAAAATEFNRTMuMTAwVVVVVVVVVVVVVUxBTUUzLjEwMFVVVVVVVVVVVVVVVVVVVVVVVVVVVVVVVVVVVVVVVVVVVVVVVVVVVVVVVVVVVVVVVVVVVVVVVVVVVVVVVVVVVf/zQsRbAAADSAAAAABVVVVVVVVVVVVVVVVVVVVVVVVVVVVVVVVVVVVVVVVVVVVVVVVVVVVVVVVVVVVVVVVVVVVVVVVVVVVVVVVVVVVVVVVVVVVVVVVVVVVVVVVVVVVVVVVVVVVVVVVVVf/zQMSkAAADSAAAAABVVVVVVVVVVVVVVVVVVVVVVVVVVVVVVVVVVVVVVVVVVVVVVVVVVVVVVVVVVVVVVVVVVVVVVVVVVVVVVVVVVVVVVVVVVVVVVVVVVVVVVVVVVVVVVVVVVVVVVVVV";
          playSound.muted = false;
          playSound.src = "/alarm.mp3";
          playSound.volume = 1;
          playSound.play();
        }
      }
    }, 15000);
    return () => clearInterval(interval);
  }, [orders]);
  useEffect(() => {
    socket.on("order", (order) => {
      addOrders(order);
    });

    return () => {
      socket.off();
    };
  }, []);
  return (
    <Box sx={{ display: "flex" }}>
      <Dialog onClose={() => setModalFirst(false)} open={modalFirst}>
        <div
          style={{
            padding: 20,
            paddingLeft: 50,
            paddingRight: 50,
            textAlign: "center",
            display: "flex",
            flexDirection: "column",
            justifyContent: "center",
            alignItems: "center",
          }}
        >
          <h3 style={{ fontSize: 32 }}>Bienvenido!</h3>
          <p style={{ marginBottom: 65, fontSize: 22 }}>
            El administrador esta listo para recibir ordenes.
          </p>
          <Button
            onClick={() => setModalFirst(false)}
            variant="contained"
            style={{ width: 160, height: 40 }}
          >
            <p style={{ fontSize: 18 }}>Ok</p>
          </Button>
        </div>
      </Dialog>
      <Router>
        <CssBaseline />
        <TbHead open={open} />
        <Navigator />
        <Main open={open}>
          <DrawerHeader />
          {loaderCategory && loaderOption && loaderOrders && loaderCodes ? (
            <Routes>
              <Route path="/" element={<Categories />} />
              <Route path="/categories" element={<Categories />} />
              <Route path="/options" element={<Options />} />
              <Route path="/features" element={<Features />} />
              <Route path="/categories/:category" element={<Products />} />
              <Route path="/orders/pending" element={<Pending />} />
              <Route path="/orders/ready" element={<Ready />} />
              <Route path="/orders/toPick" element={<Processed />} />
              <Route path="/stadistics" element={<Stadistics />} />
              <Route path="/requests" element={<Selector />} />
              <Route path="/reserves" element={<Reserves />} />
              {user.isSuperAdmin && (
                <Route path="/configuration" element={<Configuration />} />
              )}
            </Routes>
          ) : (
            <Box
              sx={{
                display: "flex",
                height: "80vh",
                width: "100%",
                justifyContent: "center",
                alignItems: "center",
              }}
            >
              <CircularProgress size={90} />
            </Box>
          )}
        </Main>
      </Router>
    </Box>
  );
}
